

@media (min-width: 992px) and (max-width:1400px) {

    /**common css start***/

    .p-100 {
        padding: 50px 0;
    }

    .p-80 {
        padding: 40px 0;
    }

    .row-1 {
        gap: 10px;
    }

    .row-2 {
        gap: 15px;
    }

    .row-3 {
        gap: 20px;
    }

    .row-4 {
        gap: 20px;
    }

    .custom-heading {
        font-size: 60px;
        line-height: 68px;
    }

    .custom-heading.ch-small {
        font-size: 24px;
        line-height: 40px;
    }

    .custom-heading.ch-xssmall {
        font-size: 14px;
    }

    .custom-heading.ch-xssmall span {
        display: inline;
    }

    /**common css End***/


    .container.top-header-s {
        max-width: 100%;
        width: 100%;
    }

    .navbar {
        padding: 20px 20px;
    }

    .navbar .logo a img {
        width: 165px;
    }

    .navbar .auth-buttons {
        gap: 10px;
    }

    .navbar .top-header-s {
        gap: 10px;
    }

    .navbar .top-header-s .menu {
        width: fit-content;
        padding: 0 5px;
        height: 40px;
        gap: 4px;
    }

    .navbar .top-header-s .menu a {
        padding: 0 8px;
        font-size: 13px;
        line-height: 15px;
    }

    .navbar .menu a {
        font-size: 13px;
    }

    .navbar .auth-buttons .button-transparent {
        padding: 10px 10px 10px 10px;
        font-size: 12px;
    }

    /***banner section css start***/

    .banner-contnet h1 {
        font-size: 66px;
        font-weight: 400;
        line-height: 70px;
    }

    /***banner section css End***/

    /***ouradvantage section start***/

    .ouradvantage .oat-card {
        width: 100%
    }

    /***ouradvantage section End***/

    /****masonry grid section start***/

    .my-masonry-grid {
        gap: 20px;
    }

    /****masonry grid section end***/

    /***evalution card start***/

    .evaluation-card img.left-s {
        top: 30px;
        left: 20px;
        max-width: 100px;
        height: 100px;
    }

    .evaluation-card img.right-s {
        top: 30px;
        right: 15px;
        width: 100px;
        height: 100px;
    }

    .evaluation-card {
        padding: 30px;
        height: 100%;
    }

    .evaluation-card .card-content .card-subtitle {
        font-size: 16px;
    }

    .evaluation-card .card-content .card-description {
        font-size: 13px;
    }

    /* .evaluation-card .card-content {
        padding-top: 25px;
    } */

    .evaluation-card .card-index {
        left: -15px;
        top: -15px;
    }

    /***evalution card End***/

    /***how it work start***/

    .hiw-inner-box {
        padding-top: 0;
    }
    /* .hib-box.box-left.one,
    .hib-box.box-left.three {
        left: -150px;
    }

    .hib-box.box-right.two,
    .hib-box.box-right.four {
        right: -150px;
    } */


    /***how it work start***/

    /***lets talk section start***/
    .lets-talk .lt-heading {
        font-size: 120px;
    }

    /***Lets talk section end***/

    /***footer section start****/
    .footer .footer-logo p {
        font-size: 16px;
        line-height: 30px;
    }

    /***footer section end***/


}

@media (max-width: 991px) {

    /***custom css start***/

    body {
        overflow-y: auto !important;
        overflow-x: hidden;
    }

    .p-100 {
        padding: 50px 0;
    }

    .p-80 {
        padding: 40px 0;
    }

    .row-1 {
        gap: 10px;
    }

    .row-2 {
        gap: 15px;
    }

    .row-3 {
        gap: 20px;
    }

    .row-4 {
        gap: 20px;
    }

    .custom-bg img.left-s,
    .custom-bg img.right-s {
        width: 130px;
    }

    .buttons .btn-primary,
    .buttons .btn-secondary {
        padding: 12px 12px 12px 12px;
    }

    .pattern-bx {
        z-index: 2;
        display: none;
    }

    .gb-inner {
        filter: none;
    }

    .custom-heading {
        font-size: 40px;
        line-height: 50px;
    }

    .custom-heading.ch-medium {
        font-size: 24px;
        line-height: 30px;
    }

    .custom-heading.ch-small {
        font-size: 20px;
        line-height: 30px;
        color: #75ea8b;
    }

    /* .custom-heading.ch-xssmall span {
        display: inline;
    } */

    .custom-heading.ch-xssmall {
        font-size: 14px;
    }

    .custom-heading.ch-medium span {
        display: inline-block;
    }

    /***custom css End***/


    .buttons {
        flex-direction: row;
        gap: 12px;
    }

    /***banner css start***/

    .banner-contnet h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .banner-contnet p {
        font-size: 16px;
        line-height: 26px;
    }

    .banner-contnet {
        gap: 20px;
        padding: 0 15px;
        margin-top: -32px;
    }

    .background-video {
        position: relative;
        top: 0;
    }

    /* .banner-contnet p span {
        display: inline;
    } */

    .landing-page {
        min-height: 100%;
        padding: 0 0 30px 0;
        /* padding: 0; */
        flex-direction: column;
        gap: 20px;
    }

    /***banner css end***/

    /***how it work css start***/

    .hiw-inner-box {
        padding-top: 0;
    }

    .how-it-work .hiw-inner {
        gap: 20px;
    }

    .hiw-inner-box .hib-box {
        margin: 10px 0;
    }

    .hiw-inner-box .hib-box .custom-heading.ch-xssmall {
        letter-spacing: 0.4px;
        font-size: 14px;
        line-height: 25px;
    }

    .how-it-work .right-s,
    .how-it-work .left-s {
        width: 130px;
    }

    .hib-box.box-left.one,
    .hib-box.box-left.three {
        left: 0;
    }

    .hib-box.box-right.two,
    .hib-box.box-right.four {
        right: 0;
    }

    .hiw-inner-box .hib-box {
        padding: 10px 0;
    }

    .hib-box img.line,
    .hib-box img.glow {
        display: none;
    }

    .how-it-work .custom-heading.ch-medium {
        font-size: 18px;
        line-height: 28px;
    }

    /***how it work css end***/

    /***our advantage start***/
    .ouradvantage .custom-heading.ch-medium span {
        display: inline;
    }

    .ouradvantage .custom-heading.ch-medium span img {
        width: 150px;
        margin-right: 8px;
    }

    /* .ouradvantage .oat-table {
        display: block;
        padding-top: 0;
        z-index: 2;
    } */

    .ouradvantage .oat-card {
        width: 100%;
    }

    /* .ouradvantage .highlighted {
        max-width: 100%;
        width: calc(430px - 150px);
        top: 0;
        height: calc(600px - 150px);
    } */

    .oat-center-box {
        max-width: 100%;
        width: calc(100% - 10px);
        margin: 0 5px;
        left: 0;
        right: 3px;
        height: calc(100% - 15px);
    }

    .ouradvantage .cci-box {
        position: absolute;
        max-width: 100%;
        width: 100%;
        top: 130px;
        left: 0;
        right: 0;
    }

    .ouradvantage .cc-card {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
    }

    .ouradvantage .cc-title {
        font-size: 12px;
    }

    /* .ouradvantage .oat-grid {
        grid-template-columns: 1fr;
        gap: 35px;
    } */



    /***our advantage End***/

    /***our benifit start***/
    .benifit-section .custom-heading.ch-medium span {
        display: inline-block;
    }

    .benifit-section .oat-box {
        padding-bottom: 10px;
        align-items: center;
    }

    .benifit-section .custom-heading.ch-xssmall {
        text-align: center;
    }

    .benifit-section .buttons {
        justify-content: center;
    }

    /***our benifit End***/

    /***evalution card start***/

    .evaluation-card {
        padding: 30px 15px;
        height: 100%;
    }

    /* .custom-evaluation-box .row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    } */

    .evaluation-card .card-header {
        margin-bottom: 10px;
    }

    .evaluation-card .card-content {
        padding-top: 10px;
    }

    .evaluation-card img.left-s {
        top: 30px;
        left: 20px;
        max-width: 100px;
        height: 100px;
    }

    .evaluation-card img.right-s {
        top: 30px;
        right: 15px;
        width: 100px;
        height: 100px;
    }

    .custom-evaluation-box {
        padding-top: 20px;
    }

    .evaluation-card .card-content .card-subtitle {
        font-size: 18px;
        line-height: 20px;
    }

    .evaluation-card .icon-bg img {
        width: 70px;
    }

    .evaluation-card .card-index {
        left: 0;
        top: -15px;
    }

    /***evalution card end***/

    /***getstart css start***/

    .getstart-section {
        background-attachment: initial !important;
        background-size: 80%;
        background-position: bottom center !important;
    }

    .getstart-section .gss-inner .evaluation-card {
        margin-top: 0;
        padding: 9px;
    }

    /***getstart css end***/


    /***pricesing table start****/


    .pricing-tabs .card .evaluation-card.ec-card {
        padding: 15px;
    }

    /* .pricing-tabs .tab {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20.36px;
        height: 40px
    } */

    .pricing-tabs {
        display: block;
    }

    .pricing-tabs .tabs {
        margin-bottom: 15px;
    }

    .pricing-tabs h4.details-title {
        padding-bottom: 0;
    }

    .pricing-tabs .details p {
        line-height: 30px;
    }

    .pricing-tabs .details {
        padding-top: 15px;
    }

    .pricing-tabs h3.card-subtitle {
        font-size: 26px;
    }

    .pricing-tabs .cards {
        flex-wrap: nowrap;
    }



    /***pricesing table start****/

    /***certificates css start***/

    .certificates .evaluation-card.ec-card {
        margin: 0 auto;
    }

    .certificates .evaluation-card.ec-card img {
        width: 100%;
    }

    .certificates .custom-heading {
        line-height: 50px;
    }

    /***certificates css End***/

    /***mysanary css start***/
    /* .my-masonry-grid .card {
        margin-bottom: 15px;
    } */

    .my-masonry-grid {
        gap: 20px
    }

    .my-masonry-grid_column:nth-child(even) .card {
        margin-top: 15px;
    }

    .my-masonry-grid .card p {
        font-size: 13px;
        margin-bottom: 0;
    }

    /***mysanary css End***/

    /***accordion-item start***/

    .accordion-item {
        padding: 10px 0;
    }

    .accordion-title {
        font-size: 16px;
        padding: 8px 0;
    }

    .accordion-content {
        font-size: 14px;
    }

    /***accordion-item end***/

    /***customer support***/

    .community-section {
        padding: 0;
    }

    .community-section .logo-circle {
        width: 40px;
        height: 40px;
        margin: 0px 8px;
    }

    .community-section .logo-circle .logo-icon img {
        width: 25px;
    }

    .community-section .stats {
        padding-bottom: 20px;
    }

    /* .community-section .stats {
        flex-direction: column;
        gap: 15px;
    } */

    /* .community-section .stat-item:after {
        display: none;
    } */

    .lets-talk .lt-heading {
        font-size: 30px;
        line-height: 65px;
    }

    .lt-inner {
        padding: 70px 0;
    }

    .lt-inner img {
        width: 100%;
        object-fit: cover;
    }

    .footer .newsletter-input {
        margin-bottom: 25px;
    }

    /***footer setion start***/

    .footer .footer-top {
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .footer .footer-logo img {
        width: 150px;
    }

    .footer .footer-logo p {
        font-size: 16px;
        line-height: 30px;
    }

    .footer .footer-links {
        gap: 15px;
        justify-content: space-between;
    }

    .footer .footer-column p {
        font-size: 14px;
    }


    /* .footer .footer-links {
        flex-direction: column;
    } */

    .footer .footer-column li a {
        font-size: 14px;
        line-height: 30px;
    }

    .footer .footer-bottom {
        padding-top: 40px;
        /* flex-direction: column; */
        gap: 20px;
    }

    .footer .footer-bottom .footer-column {
        max-width: 100%;
    }

    .footer .footer-logo {
        max-width: 100%;
    }


}


@media (max-width: 767px) {

    /***custom css start***/

    .has-scroll-smooth [data-scroll-container] {
        min-height: 100% !important;
    }

    body {
        overflow-y: auto !important;
        overflow-x: hidden;
    }

    .p-100 {
        padding: 50px 0;
    }

    .p-80 {
        padding: 40px 0;
    }

    .row-2 {
        gap: 15px;
    }

    .row-3 {
        gap: 20px;
    }

    .row-4 {
        gap: 20px;
    }

    .custom-bg img.left-s,
    .custom-bg img.right-s {
        width: 130px;
    }

    .buttons .btn-primary,
    .buttons .btn-secondary {
        padding: 12px 12px 12px 12px;
    }

    .pattern-bx {
        z-index: 2;
        display: none;
    }

    .gb-inner {
        filter: none;
    }

    .custom-heading {
        font-size: 40px;
        line-height: 50px;
    }

    .custom-heading.ch-medium {
        font-size: 20px;
        line-height: 30px;
        padding: 0 20px;
    }

    .custom-heading.ch-small {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #75ea8b;
    }

    .custom-heading.ch-xssmall span {
        display: inline;
    }

    .custom-heading.ch-xssmall {
        font-size: 14px;
        line-height: 24px;
    }

    /***custom css End***/


    .buttons {
        flex-direction: row;
        gap: 12px;
    }

    /***banner css start***/

    .banner-contnet {
        gap: 20px;
        padding: 0 15px;
        margin-top: -15px;
    }

    .banner-contnet h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .banner-contnet p {
        font-size: 14px;
        line-height: 26px;
    }

    .banner-contnet p span {
        display: inline;
    }

    .landing-page {
        min-height: 100%;
        padding: 0 0 30px 0;
        /* padding: 0; */
        flex-direction: column;
        gap: 20px;
    }

    .background-video {
        position: relative;
        top: 0 !important;
    }

    /***banner css end***/

    /***how it work css start***/

    .hiw-inner-box {
        padding-top: 0;
    }

    .how-it-work .hiw-inner {
        gap: 20px;
    }

    .hiw-inner-box .hib-box {
        margin: 10px 0;
    }

    .hiw-inner-box .hib-box .custom-heading.ch-xssmall {
        letter-spacing: 0.5px;
        text-align: center;
        font-size: 14px;
        line-height: 25px;
    }

    .how-it-work .right-s,
    .how-it-work .left-s {
        width: 130px;
    }


    .how-it-work .custom-heading.ch-medium {
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        padding: 0;
    }

    .hib-box.box-left.one ,
    .hib-box.box-left.three {
        left: 0;
    }

    .hib-box.box-right.two, 
    .hib-box.box-right.four {
        right: 0;
    }


    /***how it work css end***/

    /***our advantage start***/

    .ouradvantage {
        background-position: top !important;
    }

    .ouradvantage .custom-heading.ch-medium span {
        display: inline;
    }

    .ouradvantage .custom-heading.ch-medium span img {
        width: 150px;
        margin-right: 8px;
    }

    /* .ouradvantage .oat-table {
        display: block;
        padding-top: 0;
        z-index: 2;
    } */

    .ouradvantage .oat-card {
        width: 100%;
    }

    /* .ouradvantage .fvsxfbdt,
    .ouradvantage .highlighted {
        max-width: 100%;
        width: 140px;
        top: 0;
    } */

    .oat-center-box {
        max-width: 100%;
        width: calc(100% - 10px);
        margin: 0 5px;
        top: 7px;
    }

    .ouradvantage .cci-box {
        position: absolute;
        max-width: 100%;
        width: 100%;
        top: 130px;
        left: 0;
        right: 0;
    }

    .ouradvantage .cc-card {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        /* padding: 0 15px; */
    }

    /* .ouradvantage .oat-table {
        display: block;
        padding-top: 0;
        z-index: 2;
        margin-top: 20px;
    } */

    /* .ouradvantage .oat-grid {
        grid-template-columns: 1fr;
        gap: 35px;
    } */

    /* .ouradvantage .column {
        border: 1px solid #FFFFFF4D;
        -webkit-backdrop-filter: blur(22px);
        backdrop-filter: blur(22px);
        border-radius: 20px;
        padding: 15px 0;
    } */

    /* .ouradvantage .cc-title {
        height: auto;
        font-size: 16px;
    } */

    /***our advantage End***/

    /***our benifit start***/
    .benifit-section .custom-heading.ch-medium span {
        display: inline-block;
    }

    .benifit-section .oat-box {
        padding-bottom: 10px;
        align-items: center;
    }

    .benifit-section .custom-heading.ch-xssmall {
        text-align: center;
    }

    .benifit-section .buttons {
        justify-content: center;
    }

    /***our benifit End***/

    /***evalution card start***/

    .evaluation-card {
        padding: 30px 15px;
        height: 100%;
    }

    .custom-evaluation-box .row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .evaluation-card .card-header {
        margin-bottom: 10px;
    }

    .evaluation-card .card-content {
        padding-top: 10px;
    }

    .evaluation-card img.left-s {
        top: 30px;
        left: 20px;
        max-width: 100px;
        height: 100px;
    }

    .evaluation-card img.right-s {
        top: 30px;
        right: 15px;
        width: 100px;
        height: 100px;
    }

    .custom-evaluation-box {
        padding-top: 20px;
    }

    .evaluation-card .card-content .card-subtitle {
        font-size: 18px;
        line-height: 20px;
    }

    .evaluation-card .icon-bg img {
        width: 70px;
    }

    .evaluation-card .card-index {
        left: 0;
        top: -15px;
    }

    /***evalution card end***/

    /***getstart css start***/

    .getstart-section {
        background-attachment: initial !important;
        background-size: contain;
        background-position: bottom center !important;
    }

    .getstart-section .gss-inner .evaluation-card {
        margin-top: 0;
        padding: 9px;
    }

    /***getstart css end***/


    /***pricesing table start****/


    .pricing-tabs .card .evaluation-card.ec-card {
        padding: 15px;
    }

    .pricing-tabs .tab {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20.36px;
        height: 40px
    }

    .pricing-tabs {
        display: block;
    }

    .pricing-tabs .tabs {
        overflow: hidden;
        overflow-x: auto;
        display: block;
        white-space: nowrap;
        margin-bottom: 15px;
        padding: 4px 6px;
        height: 50px;
    }

    .pricing-tabs h4.details-title {
        padding-bottom: 0;
    }

    .pricing-tabs .details p {
        line-height: 30px;
    }

    .pricing-tabs .details {
        padding-top: 15px;
    }

    .pricing-tabs h3.card-subtitle {
        font-size: 26px;
    }

    .pricing-tabs .cards {
        flex-wrap: wrap;
    }



    /***pricesing table start****/

    /***certificates css start***/

    .certificates .evaluation-card.ec-card {
        margin: 0 auto;
    }

    .certificates .evaluation-card.ec-card img {
        width: 100%;
    }

    /***certificates css End***/

    /***mysanary css start***/
    /* .my-masonry-grid .card {
        margin-bottom: 0px;
    } */

    .my-masonry-grid {
        gap: 20px
    }

    .my-masonry-grid_column:nth-child(even) .card {
        margin-top: 15px;
    }

    /***mysanary css End***/

    /***accordion-item start***/

    .accordion-item {
        padding: 10px 0;
    }

    .accordion-title {
        font-size: 16px;
        padding: 8px 0;
    }

    .accordion-content {
        font-size: 14px;
    }

    /***accordion-item end***/

    /***customer support***/

    .community-section {
        padding: 0;
    }

    .community-section .logo-circle {
        width: 40px;
        height: 40px;
        margin: 0px 8px;
    }

    .community-section .logo-circle .logo-icon img {
        width: 25px;
    }

    .community-section .stats {
        padding-bottom: 20px;
    }

    /* .community-section .stats {
        flex-direction: column;
        gap: 15px;
    } */

    /* .community-section .stat-item:after {
        display: none;
    } */

    .lets-talk .lt-heading {
        font-size: 30px;
        line-height: 65px;
    }

    .lt-inner {
        padding: 70px 0;
    }

    .lt-inner img {
        width: 100%;
        object-fit: cover;
    }

    .footer .newsletter-input {
        margin-bottom: 25px;
    }

    /***footer setion start***/

    .footer .footer-top {
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .footer .footer-logo img {
        width: 150px;
    }

    .footer .footer-logo p {
        font-size: 16px;
        line-height: 25px;
    }

    .footer .footer-links {
        gap: 15px;
    }

    .footer .footer-column p {
        font-size: 14px;
    }


    /* .footer .footer-links {
        flex-direction: column;
    } */

    .footer .footer-column li a {
        font-size: 14px;
        line-height: 30px;
    }

    .footer .footer-bottom {
        padding-top: 40px;
        flex-direction: column;
        gap: 20px;
    }

    .footer .footer-bottom .footer-column {
        max-width: 100%;
    }

}


@media (max-width: 576px) {

    /***banner css start***/
    .banner-contnet h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .banner-contnet h1>br {
        display: none;
    }

    /***banner css end***/

    .community-section .stats {
        flex-direction: row;
        gap: 25px;
    }

    .community-section .stat-value {
        font-size: 18px;
        margin-bottom: 5px;
    }

    

    /***getstart css start***/

    .custom-heading {
        font-size: 24px;
        line-height: 40px;
    }

    /***getstart css end***/

    /***trader css start***/

    .masonry-s {
        padding: 10px;
    }

    /****Trader css End****/

    .oat-center-box {
        max-width: 100%;
        width: calc(100% - 10px);
        margin: 0 5px;
        left: 0;
    }

    /***benifit section***/

    .benifit-section .custom-heading.ch-medium {
        text-align: center;
        padding: 0 20px;
    }


    /***footer setion start***/

    .footer-logo {
        max-width: 100%;
    }

    .footer .footer-links {
        flex-direction: column;
    }

    .footer-links {
        gap: 15px;
    }

    .footer-column p {
        font-size: 14px;
    }



}



@media screen and (min-width:993px) {
    .hamburger,
    .afvsdgvbsrg{
        display: none !important;
    }

}


.maxcadvzdv{
    max-width: 1920px;
    margin: auto;
    padding: 0 15px;
}

.maxcadvzdv .oat-table{
    overflow: hidden;
    overflow-x: auto;
}

@media screen and (max-width:576px) {
    .ouradvantage .fvsxfbdt,
    .ouradvantage .highlighted {
        width: 100%;
        min-width: 140px;
    }

    .ouradvantage .cc-card{
        font-size: 12px;
    }

    .logo-circle{
        width: 50px;
        height: 50px;
    }

    .ouradvantage .highlighted {
        height: 470px;
    }

    .maxcadvzdv {
        margin-bottom: -50px;
    }
}

@media screen and (max-width:1400px) {
    .ouradvantage .oat-card {
        max-width: 1200px;
    }
}