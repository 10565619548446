/* Navbar */



.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    z-index: 4;
    /* transition: all 0.5s ease; */
    position: sticky;
    top: -150px;
    background-color: transparent;
    transition: all 0.8s linear;
}

header.navbar.active {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #0000007d;
    top: 0;
}

.navbar .logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar .menu {
    display: flex;
    gap: 15px;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    border: 1px solid #FFFFFF4D;
    background: #6A748E38;
    width: 595px;
    height: 47px;
    border-radius: 100px;
    justify-content: space-around;
    align-items: center;
    transition: all 0.5s ease;
    padding: 0 15px;

}

.navbar .menu a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0.9px;
}

.navbar .menu a span {
    color: #75EA8B;
}

.navbar .menu a:hover {
    color: #32cd32;
}


.navbar span svg {
    width: 18px;
    height: 15px;
    display: inline;
    color: #75EA8B;
}

.navbar .auth-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar .btn-login {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
}

.navbar .btn-login:hover {
    color: #32cd32;
}

/***sidebar start****/
/* Sidebar styles */

.sidebar-header .logo a img {
    width: 160px;
    margin: 0 auto;
    display: block;
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    background-color: #000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(calc(100% + 20px));
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
}

.sidebar.open {
    transform: translateX(0);
}


.sidebar-header {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #191919
        /* border-bottom: 1px solid #ccc; */
}

.sidebar-header .close-btn {
    font-size: 36px;
    cursor: pointer;
    color: #fff;
}

.sidebar-menu {
    padding: 15px;
}

.sidebar-menu a {
    display: block;
    padding: 10px 5px;
    text-decoration: none;
    color: #dfdfdfd4;
    transition: background 0.3s ease;
    border-top: 1px solid #dfdfdfd4;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.sidebar-menu a:hover {
    color: #75EA8B;
}

.sidebar-menu a:first-child {
    border-top: none;
}

.sidebar .auth-buttons {
    padding: 20px 15px 0;
    justify-content: center;
}

.sidebar.open .auth-buttons .button-transparent {
    padding: 8px 8px 8px 8px;
    font-size: 13px;
    width: 100%;
    text-align: center;
}


/* Overlay Background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

/* Show overlay when menu is open */
.b-active .sidebar.open~.overlay {
    display: block;
}

/* .b-active ~ .lenis-smooth{
  overflow: hidden;
} */
.lenis.lenis-smooth.has-scroll-init.lenis-stopped {
    overflow: hidden;
}


/* Prevent body scroll when menu is open */
body.b-active {
    overflow: hidden;
}


/* Hamburger styles */
.hamburger {
    cursor: pointer;
}

.hamburger .line {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: transform 0.3s ease-in-out;
}

.hamburger .line.open {
    transform: rotate(45deg);
}

.close-btn {
    position: absolute;
    left: 10px;
    top: -5px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--white);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-box-shadow: 2px 4px 12px transparent;
    -moz-box-shadow: 2px 4px 12px transparent;
    box-shadow: 2px 4px 12px transparent;
}

/* Prevent body scroll */
.no-scroll {
    overflow: hidden;
}

/* Blur overlay */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
}


/***sidebar end***/

/* Responsive Styles */
@media (min-width: 768px) {
    .content h1 {
        font-size: 3rem;
    }

    .content p {
        font-size: 1.2rem;
    }

    .buttons {
        flex-direction: row;
    }
}



@media (min-width: 1024px) {
    .content h1 {
        font-size: 4rem;
    }

    .content p {
        font-size: 1.5rem;
    }
}


/* Hamburger Menu */
.hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.hamburger .line {
    width: 22px;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
}

.hamburger .line.open:nth-child(1) {
    transform: rotate(45deg) translateY(10px);
}

.hamburger .line.open:nth-child(2) {
    opacity: 0;
}

.hamburger .line.open:nth-child(3) {
    transform: rotate(-45deg) translateY(-10px);
}


.desktoplogo {
    display: block;
}

.mobilelogo {
    display: none;
}

@media (min-width: 993px) {

    .d-menu {
        display: none !important;
    }
}


@media (max-width: 992px) {

    .container.top-header-s {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }

    header.navbar.scrollfix {
        position: sticky;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-box-shadow: 0 0 40px 0 #75ea8b54;
        -moz-box-shadow: 0 0 40px 0 #00d5ff33;
        box-shadow: 0 0 40px 0 #75ea8b54;
        top: 0;
        animation: fadeInDown 0.5s ease;
    }

    .sidebar .auth-buttons,
    .navbar .sidebar .auth-buttons {
        margin-right: 0;
    }

    .sidebar .auth-buttons,
    .navbar .auth-buttons {
        gap: 10px;
    }

    .sidebar .auth-buttons .button-transparent,
    .navbar .auth-buttons .button-transparent {
        padding: 6px 10px 6px 10px;
        /* font-size: 12px; */
    }


    .navbar .menu {
        display: none;
    }

    .navbar .menu.open {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 0;
        border: none;
        padding: 8px 0;
        margin-top: 10px;
        background: none;
        backdrop-filter: none;
    }

    .navbar {
        padding: 15px 15px;
        height: 75px;
    }

    .navbar .menu a {
        padding: 10px;
        display: block;
    }

    .hamburger .line {
        height: 2px;
        background-color: #fff;
    }

    /* .logo a img {
        width: 55px;
    } */

    .auth-buttons.login-n {
        padding: 6px 15px;
    }

    .b-active .navbar .d-menu .auth-buttons {
        display: none;
    }

}


@media (max-width: 576px) {

    .navbar {
        padding: 10px 10px;
        height: auto;
    }

    .logo a img {
        width: 50px;
    }

    .desktoplogo {
        display: none;
    }

    .mobilelogo {
        display: block;
    }

    .navbar .auth-buttons .button-transparent,
    .navbar .auth-buttons .button-transparent .login-n {
        font-size: 14px;
    }



    .sidebar .auth-buttons,
    .navbar .sidebar .auth-buttons {
        margin-right: 0;
    }

    .container.top-header-s {
        padding: 0;
    }

    /***community section***/

    .community-section .stat-item:after {
        display: none;
    }
}


/* Default visibility for logos */


@media (max-width: 450px) {

    .sidebar {
        width: 100%;
    }

}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.nlb-logo{
    display: flex;
    justify-content: center;
    padding: 20px 15px 0;
    gap: 10px;
}

.nlb-logo .new-login-btn{
    width: 100%;
    text-align: center;
    backdrop-filter: blur(14px);
    background: #6a748e38;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .8px;
    padding: 8px 20px;
    transition: all .3s ease-in-out;
}

.nlb-logo .new-login-btn:hover{
    background-color: #75EA8B;
}