html {
    scroll-behavior: initial !important;
}

/* html.has-scroll-smooth {
    overflow: visible !important;
} */

* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a,
a:hover {
    text-decoration: none;
    color: var(--black);
}

.common-word-wrap {
    word-wrap: break-word;
}

.custom-heading {
    font-size: 80px;
    font-weight: 500;
    line-height: 63px;
    color: #ffffff;
}

.custom-heading.ch-medium {
    font-size: 46px;
    font-weight: 500;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
}

.custom-heading.ch-medium span {
    display: block;
}

.custom-heading.ch-small {
    font-size: 28px;
    font-weight: 500;
    line-height: 63px;
    color: #ffffff;
}

.custom-heading.ch-xssmall {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #ffffff;
}

.custom-heading.ch-xssmall span {
    display: block;
}

svg {
    width: 13px;
    height: 13px;
}

.row-4 {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.row-3 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.row-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.row-1 {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.text-center {
    text-align: center;
}

.p-relative {
    position: relative;
}

.custom-bg {
    position: relative;
}

.custom-bg img.left-s {
    position: absolute;
    left: 0;
    top: 0;
}

.custom-bg img.right-s {
    position: absolute;
    right: 0;
    bottom: 0;
}

/* .c-scrollbar_thumb {
    background-color: #fff !important;
} */

/***conyainer css start****/


/* @media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

 @media (min-width: 768px) {
    .container {
        width: 720px;
    }
} 

@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1320px;
    }
} */


@media (max-width: 576px) {
    .container {
        width: 100%;
    }
}

/***conyainer css End****/



/* --- Website Fonts --- */
@font-face {
    font-family: "Inter", sans-serif;
    src: url("../fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
    font-display: swap;
}

/* --- Body tag Css --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--celeste);
    background-color: #000;
    background-image: url('../img/Stars.png');
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    height: 100vh;
    -webkit-animation: mymove 8s linear infinite;
    animation: mymove 8s linear infinite;
    overflow-x: hidden;
    overflow-y: auto;
}

@keyframes mymove {
    from {
        background-position: 0% -100%;
    }

    to {
        background-position: 0% 0%;
    }
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

@font-face {
    font-family: 'Product Sans';
    src: url('../fonts/Producrsans/ProductSans-Regular.eot');
    src: url('../fonts/Producrsans/ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Producrsans/ProductSans-Regular.woff2') format('woff2'),
        url('../fonts/Producrsans/ProductSans-Regular.woff') format('woff'),
        url('../fonts/Producrsans/ProductSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.p-100 {
    padding: 100px 0;
}

.p-80 {
    padding: 80px 0;
}

.p-50 {
    padding: 50px 0;
}

/***custom button css***/

.buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.button-transparent {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    border: 1px solid #FFFFFF4D;
    background: #6A748E38;
    color: #fff;
    padding: 12px 20px 12px 20px;
    border-radius: 50px;
    border: 1px 0px 0px 0px;
    transition: all .3s ease-in-out;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
}

.button-transparent:hover {
    color: #75EA8B;
}


.buttons .btn-primary {
    color: #001D0F;
    padding: 12px 20px 12px 20px;
    gap: 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    display: inline-block;
    background: #75EA8B;
    border: 1px solid #FFFFFF3D;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    height: 41px;
    transition: all .3s ease-in-out;
}

.buttons .btn-primary:hover {
    background: #64c175;
    border: 1px solid #64c175;
    box-shadow: 0 0 80px 0 #64c175;
}

.buttons .btn-secondary {
    color: #001D0F;
    padding: 12px 20px 12px 20px;
    gap: 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    display: inline-block;
    background: #ffffff;
    border: 1px solid #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    height: 41px;
    transition: all .3s ease-in-out;
}

.buttons .btn-secondary:hover {
    background: #f1f1f1;
}




/***bannercss***/

.pattern-bx {
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    right: 0;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: -5;
    top: 0;
}

.gb-inner,
.gradient-background-bx {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.gb-inner {
    filter: blur(300px);
    transform: translateZ(0);
}

.gb-shape.gb-s-2 {
    animation-name: gbsAnimation2;
    background-image: radial-gradient(#75EA8B70, #75EA8BAD);
    height: 460px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
}

.gb-shape {
    border-radius: 100%;
    mix-blend-mode: lighten;
    position: absolute;
}

.landing-page {
    position: relative;
    min-height: 100vh;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
}

.background-image {
    position: absolute;
    inset: 0;
    /* background: url('/path-to-your-uploaded-image.png') no-repeat center center/cover; */
    z-index: 1;
}

/* 
.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
} */

.banner-contnet {
    position: relative;
    z-index: 3;
    text-align: center;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.banner-contnet h1 {
    font-family: Product Sans;
    font-size: 80px;
    font-weight: 400;
    line-height: 95px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.banner-contnet h1 .highlight {
    color: #32cd32;
    /* Green Highlight */
}

.banner-contnet p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0.8px;
    margin-bottom: 0;
}

.banner-contnet p span {
    display: block;
}

.background-video {
    position: absolute;
    top: -85px;
    /* top: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.banner-contnet .buttons .btn-primary, 
.banner-contnet .buttons .btn-secondary {
    max-width: 155px;
    width: 100%;
}

/***How it work***/

/* .how-it-work {
    background: url('../img/scrollimg/left.png') left top no-repeat, url('../img/scrollimg/right.png') right bottom no-repeat;
} */

.how-it-work {
    position: relative;
    /* overflow-x: hidden; */
}

.how-it-work .left-s {
    position: absolute;
}

.how-it-work .right-s {
    position: absolute;
    right: 0;
    bottom: 0;
}


.how-it-work .custom-heading.ch-medium {
    max-width: 100%;
    margin: 0 auto;
    font-size: 23px;
    font-weight: 400;
    line-height: 1.7;
}

.how-it-work .hiw-inner {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.hiw-inner-box {
    display: flex;
    gap: 50px;
    padding-top: 30px;
    position: relative;
}

.hiw-inner-box .hib-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 12px 50px;
    position: relative;
    /* background: #00163038;
    border: 1px solid #FFFFFF5E;
    backdrop-filter: blur(22px); */
    border-radius: 14px;
    margin: 20px 0;
    /* overflow: hidden; */

}



.hiw-inner-box .hib-box .custom-heading.ch-xssmall {
    letter-spacing: 0.8px;
}

.hib-box img.line {
    width: 5px;
    height: auto;
    position: absolute;
    left: 15px;
    top: auto;
    bottom: -15px;
}

.hib-box img.glow {
    width: 280px;
    height: 108%;
    position: absolute;
    left: 15px;
    top: auto;
    bottom: -125px;
    object-fit: contain;
}

.hib-box span {
    width: 12px;
    height: 12px;
    display: inline-block;
    padding-right: 22px;
    color: #75ea8b;
}

/***our advantage***/

.ouradvantage .custom-heading.ch-medium span {
    display: block;
}

.ouradvantage {
    opacity: 1 !important;
    transition: opacity 0.5s ease-out;
}

/* Container */
.ouradvantage .oat-table {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    z-index: 2;
    position: relative;
}

/* Card */
.ouradvantage .oat-card {
    border-radius: 12px;
    width: 100%;
    max-width: 1400px;
}

/* Logo Wrapper */
.logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
    top: -30px;
    height: 70px;
}

.logo-circle {
    background: #75EA8B;
    border: 2px solid #D0FFD9;
    /* Bright green */
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px rgba(0, 255, 133, 0.8);
    display: inline-flex;
    margin: 0px 15px;
}

.oat-center-box .cc-title {
    position: relative;
    top: 60px;
}

/* .ouradvantage .cc-inner-content:after {
    position: absolute;
    content: "";
    height: 530.89px;
    width: 370px;
    left: -21px;
    top: -64px;
    z-index: -9;
    border: 1px solid #FFFFFF4D;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    border-radius: 20px;
} */

.ouradvantage .cci-box {
    /* position: relative; */
    position: absolute;
    height: auto;
    width: 100%;
    top: 130px;
    bottom: auto;
    left: 0;
}

/* .ouradvantage .cci-box:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    z-index: -9;
    left: 10px;
    top: -132px;
    border-radius: 14px;
    border-left: 2px solid #75EA8B;
    border-top: 2px solid #75EA8B;
    border-right: none;
    border-bottom: none;
    width: 99px;
    height: 106.6px;
    border-radius: 14px 0 0 0;
} */





/* .ouradvantage .cci-box:after {
    right: 0; 
} */
/* 
.ouradvantage .cci-box:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    z-index: -9;
    right: 8px;
    bottom: -51px;
    border-radius: 14px;
    border-right: 2px solid #75EA8B;
    border-bottom: 2px solid #75EA8B;
    border-left: none;
    border-top: none;
    width: 99px;
    height: 106.6px;
    border-radius: 0 0 14px 0;

} */

.ouradvantage .logo-icon {
    font-size: 24px;
    color: #0e141a;
    font-weight: bold;
}

/* Grid */
.ouradvantage .oat-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

/* Column */
.ouradvantage .column {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

.ouradvantage .highlighted {
    color: #fff;
    position: relative;
    height: 510px;
    /* width: 370px; */
    top: -71px;
    z-index: -9;
    border: 1px solid #FFFFFF4D;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    border-radius: 20px;
}

/* Rows */
.ouradvantage .cc-card {
    border-bottom: 1px solid #FFFFFF33;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    white-space: nowrap;
}

.ouradvantage .cc-card:last-child {
    border-bottom: none;
}


.oat-center-box {
    position: absolute;
    background: #00163038;
    border: 1px solid #FFFFFF26;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    height: calc(100% - 18px);
    /* width: 352.61px; */
    left: 0;
    right: 0;
    top: 9px;
    z-index: -1;
    border-radius: 14px;
    overflow: hidden;
    margin: 0 7px;
}

.oat-center-box:before {
    content: '';
    z-index: -2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 200%;
    height: 40%;
    border-radius: 50%;
    background: conic-gradient(rgba(0, 0, 0, 0) 0%, #75EA8B 25%, rgba(0, 0, 0, 0) 50%, #75EA8B 75%, rgba(0, 0, 0, 0) 100%);
    animation: rotateGlow 30s linear infinite;
    filter: blur(22px);
}

.oat-center-box:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #000000;
    border-radius: 14px;
}

/* Keyframes for rotation */
@keyframes rotateGlow {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}



/* .cc-inner-content:after {
    position: absolute;
    content: "";
    background: #00163038;
    border: 1px solid #FFFFFF26;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    height: 509.78px;
    width: 348.61px;
    left: 11px;
    right: 0;
    top: 9px;
    z-index: -1;
    border-radius: 14px;
} */

.ouradvantage .cc-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 70px;
}

.ouradvantage .cc-title span {
    display: block;
}

.ouradvantage .cc-card span {
    width: 25px;
    height: 25px;
    top: 704px;
    border: 1px 0px 0px 0px;
    opacity: 1;
    background: #75EA8B;
    border: 1px solid #001D0F;
    display: inline;
    padding: 7px 5px;
    border-radius: 50px;
    display: inline-grid;
    margin-right: 10px;

}

/* Media Query for Responsiveness */
/* @media screen and (max-width: 768px) {
    .ouradvantage .oat-grid {
        grid-template-columns: 1fr;
        gap: 35px;
    }
} */


/***benifit section***/


.benifit-section .custom-heading.ch-medium span {
    display: block;
}

.benifit-section .custom-heading.ch-medium {
    text-align: left;
}

.benifit-section .buttons {
    justify-content: start;
}

.benifit-section .custom-heading.ch-xssmall {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    letter-spacing: 0.7px;
}

/***custom evalution box***/

.custom-evaluation-box {
    padding-top: 15px;
}

.evaluation-card {
    /* background: rgb(0 0 0); */
    border: 2px solid transparent;
    border-radius: 14px;
    padding: 50px 50px;
    width: 100%;
    /* height: 100%; */
    height: 405px;
    text-align: center;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 0px 8px;
    border: 1px solid #FFFFFF5E;
    background: #00163038;
    backdrop-filter: blur(22px);
    overflow: hidden;
    cursor: pointer;
}

.evaluation-card img.left-s {
    position: absolute;
    top: 30px;
    left: 15px;
    width: 195px;
    height: 174px;
}

.evaluation-card img.right-s {
    position: absolute;
    top: 30px;
    right: 15px;
    width: 195px;
    height: 174px;
}

/* .evaluation-card .card-content {
    padding-top: 40px;
} */

.evaluation-card .card-header {
    position: relative;
    margin-bottom: 20px;
}

.evaluation-card .card-index {
    position: absolute;
    top: -35px;
    left: -35px;
    font-size: 14px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #75EA8B;
}

.evaluation-card .icon-container {
    margin: 0 auto;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.evaluation-card .icon-bg img {
    width: 90px;
    height: auto;
}

.evaluation-card .card-content .card-title {
    color: #75ea8b;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    text-transform: capitalize;
}

.evaluation-card .card-content .card-subtitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 26.63px;
    text-align: center;
    color: #fff;
}

.evaluation-card .card-content .card-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
    letter-spacing: 0.5px;
    max-width: 420px;
    margin: 0 auto;
}


.evaluation-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.evaluation-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.evaluation-card.ec-card {
    position: relative;
}


.evaluation-card.ec-card::before {
    /* position: absolute;
    content: "";
    border-radius: 14px;
    height: 100px;
    width: 100px;
    margin-top: -3px;
    margin-left: -3px;
    left: 0;
    top: 0;
    border-top: 2px solid #75EA8B;
    border-left: 2px solid #75EA8B;
    border-right: none;
    border-bottom: none;
    border-radius: 14px 4px; */
    content: '';
    z-index: -2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 200%;
    height: 30%;
    border-radius: 50%;
    background: conic-gradient(rgba(0, 0, 0, 0) 0%, #75EA8B 25%, rgba(0, 0, 0, 0) 50%, #75EA8B 75%, rgba(0, 0, 0, 0) 100%);
    animation: rotateGlow 30s linear infinite;
    filter: blur(22px);
    opacity: 0;
    cursor: pointer;
    transition: all .5s ease-in-out;

}

.evaluation-card.ec-card:hover:before,
.evaluation-card.ec-card:hover::after {
    opacity: 1;

}



.evaluation-card.ec-card::after {
    /* position: absolute;
    content: "";
    border-radius: 14px;
    height: 100px;
    width: 100px;
    margin-bottom: -3px;
    margin-right: -3px;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid #75EA8B;
    border-right: 2px solid #75EA8B;
    border-left: none;
    border-top: none;
    border-radius: 0 4px 14px; */
    content: '';
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #000000;
    border-radius: 14px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}


/***custom evalution box end***/

/***mask section css***/

.mask-section img {
    width: 100%;
}

/***mask section css end***/

/***getstart-section css***/

.getstart-section .custom-heading.ch-xssmall {
    max-width: 715px;
    text-align: center;
    margin: 0 auto;
}

.getstart-section .gss-inner .evaluation-card {
    padding: 15px;
    height: 100%;
    margin-top: 50px;
    backdrop-filter: blur(1px);
}


.getstart-section .gss-inner .evaluation-card img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/***getstart-section css End***/

/***price table css start***/

.pricetable .custom-heading {
    background: linear-gradient(90deg, #75EA8B 11.42%, #FFFFFF 35.25%);
    -webkit-background-clip: text;
    color: transparent;
}


.pricing-tabs .tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    background: #0915196B;
    border: 1px solid #FFFFFF26;
    backdrop-filter: blur(7px);
    border-radius: 50px;
    max-width: 577px;
    margin: 0 auto;
    padding: 5px 6px;
    height: 57px;
}

.pricing-tabs .tab {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1;
    border-radius: 50px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.5px;
    height: 45px;
}

.pricing-tabs .tab::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #75EA8B;
    transition: left 0.3s ease;
    z-index: -1;
}

.pricing-tabs .tab.active {
    color: #001D0F;
}

.pricing-tabs .tab.active::before {
    left: 0;
}

.pricing-tabs .tab:hover::before {
    left: 0;
}

.pricing-tabs .tab:hover {
    color: #001D0F;
}

.pricing-tabs .cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}


.pricing-tabs .card {
    padding: 12px 12px;
    border-radius: 14px;
    width: 440px;
    height: 100%;
    text-align: left;
    background: #00163045;
    border: 1px solid #FFFFFF26;
    backdrop-filter: blur(11px);
    position: relative;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

.pricing-tabs .card .evaluation-card.ec-card {
    height: 100%;
    padding: 28.67px 37.28px;
    box-shadow: none;
    border: 1px solid transparent;
    background: none;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    transition: opacity 0.3s ease-in-out;

}

.pricing-tabs .card .evaluation-card.ec-card::before,
.pricing-tabs .card .evaluation-card.ec-card::after {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.pricing-tabs .card:hover .evaluation-card.ec-card {
    background: #00163045;
    border: 1px solid #FFFFFF26;
    backdrop-filter: blur(11px);
}

.pricing-tabs .card:hover .evaluation-card.ec-card::before,
.pricing-tabs .card:hover .evaluation-card.ec-card::after {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.pricing-tabs .card:hover .choose-plan,
.pricing-tabs .card:hover .choose-plan:before {
    left: 0;
    color: #001D0F;
}


.pricing-tabs .choose-plan {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 14px;
    overflow: hidden;
    z-index: 1;
    transition: color 0.3s ease;
    background: #00000036;
    border: 1px solid #FFFFFF3D;
    color: #75EA8B;
    font-weight: 500;
    letter-spacing: 0.7px;
    border-radius: 10px;
}

.pricing-tabs .choose-plan::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #75EA8B;
    z-index: -1;
    transition: left 0.3s ease;
}

.pricing-tabs .choose-plan:hover {
    color: #001D0F;
}

.pricing-tabs .choose-plan:hover::before {
    left: 0;
}

.pricing-tabs .details p {
    margin: 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    color: #A4D5E2;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.8px;
}

.pricing-tabs .details p span {
    color: #fff;
}

.pricing-tabs .details {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pricing-tabs .view-all {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #75EA8B;
    margin-top: 0;
    letter-spacing: 0.7px;
}

.pricing-tabs .view-all span {
    padding-left: 7px;
}

/* .pricing-tabs .view-all:hover {
    text-decoration: underline;
} */

.pricing-tabs h2.card-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #75EA8B;
}

.pricing-tabs h3.card-subtitle {
    font-size: 36px;
    font-weight: 500;
    line-height: 43.57px;
    text-align: left;
    color: #ffffff;
}

.pricing-tabs h3.card-subtitle span {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #4F6477;
    letter-spacing: 0.8px;
    padding-left: 4px;
}

.pricing-tabs h4.details-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 13px;
}

.pricing-tabs p.card-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #A4D5E2;
}


.vsegseg{
    display: none !important;
}

@media screen and (max-width:768px) {
    .vsegseg{
        display: block !important;
    }

    .pricing-tabs .cards.vsbsrbs > div{
        display: none;
    }
    .pricing-tabs .cards.vsbsrbs.step2box > div:nth-child(2),
    .pricing-tabs .cards.vsbsrbs.step1box > div:nth-child(1){
        display: block;
    }
}

/***price table css end***/

/***certificates css start***/

.certificates .custom-heading {
    background: linear-gradient(90deg, #75EA8B 55.22%, #FFFFFF 89.68%);
    -webkit-background-clip: text;
    color: transparent;
    line-height: 90px;
}

.certificates .evaluation-card.ec-card {
    max-width: 420px;
    margin: 40px auto 0 auto;
    padding: 10.48px;
    height: 100%;
    background: #00163045;
    border: 1px solid #FFFFFF26;
    backdrop-filter: blur(11px);
    box-shadow: none;
}

.certificates .pattern-bx {
    z-index: -1;
    position: absolute;
    overflow: visible;
}



/***certificates css End***/

/***Priceindustry css start***/

.priceindustry .pricing-tabs .cards {
    border: 1px solid #FFFFFF4D;
    backdrop-filter: blur(4px);
    max-width: 1170px;
    border-radius: 14px;
    padding: 12px 12px;
    margin: 0 auto;
}

.priceindustry .evaluation-card.ec-card {
    padding: 0;
    height: 100%;
    max-width: 1164px;
    box-shadow: none;
}

.priceindustry .pricing-tabs .choose-plan {
    width: 100%;
    padding: 0;
    margin-top: 43px;
    font-size: 18px;
    font-weight: 500;
    background: #00000036;
    border: 1px solid #FFFFFF3D;
    backdrop-filter: blur(94px);
}

.priceindustry .pricing-tabs .choose-plan span {
    color: #ffffff;
}

.priceindustry .pricing-tabs .choose-plan:hover span {
    color: #001D0F;
}


.priceindustry .comparison {
    text-align: center;
    padding: 2px;
    border: none;
}

.priceindustry .comparison table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    border-bottom: 1px solid #FFFFFF33;
    background: #00163045;
    backdrop-filter: blur(11px);
}

.priceindustry .comparison td,
.priceindustry .comparison th {
    border: 1px solid #FFFFFF33;
    empty-cells: show;
    padding: 0 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.6px;
}

.priceindustry .compare-heading {
    font-size: 18px;
    font-weight: bold !important;
    border-bottom: 0 !important;
    padding-top: 10px !important;
}

.comparison tbody tr:nth-child(odd) {
    display: none;
}



.priceindustry .comparison th {
    font-weight: normal;
    padding: 0;
    border-bottom: 1px solid #FFFFFF33;
    border-top: none;
}

.priceindustry .comparison tr td:first-child {
    text-align: left;
    border-left: none;
}

.priceindustry .comparison tr td:last-child {
    border-right: none;
}

.priceindustry .comparison .tl {
    color: #FFF;
    padding: 30px;
    border-right: 1px solid #FFFFFF33;
    border-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
}

.priceindustry .comparison .price-info {
    padding: 30px 15px 30px 15px;
}

.priceindustry .comparison .price-was {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    padding-bottom: 6px;
}

.priceindustry .comparison .price-now,
.priceindustry .comparison .price-now span {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
}

.priceindustry .comparison .price-now span {
    font-size: 32px;
}

.priceindustry .comparison .price-small {
    font-size: 18px !important;
    position: relative;
    top: -11px;
    left: 2px;
}



@media (max-width: 767px) {

    .priceindustry .comparison td:first-child,
    .priceindustry .comparison th:first-child {
        display: none;
    }

    .priceindustry .comparison tbody tr:nth-child(odd) {
        display: table-row;
        background: #F7F7F7;
    }

    .priceindustry .comparison .row {
        background: #FFF;
    }

    .priceindustry .comparison td,
    .priceindustry .comparison th {
        border: 1px solid #CCC;
    }

    .priceindustry .price-info {
        border-top: 0 !important;

    }

}

@media (max-width: 639px) {
    .priceindustry .comparison .price-buy {
        padding: 5px 10px;
    }

    .priceindustry .comparison td,
    .priceindustry .comparison th {
        padding: 10px 5px;
    }

    .priceindustry .comparison .hide-mobile {
        display: none;
    }

    .priceindustry .comparison .price-now span {
        font-size: 16px;
    }

    .priceindustry .comparison .price-small {
        font-size: 16px !important;
        top: 0;
        left: 0;
    }

    .priceindustry .comparison .qbse,
    .priceindustry .comparison .qbo {
        font-size: 12px;
        padding: 10px 5px;
    }

    .priceindustry .comparison .price-buy {
        margin-top: 10px;
    }

    .priceindustry .compare-heading {
        font-size: 13px;
    }
}



.comparison table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 14px;
    overflow: hidden;
    width: 100%;
}

/***Priceindustry css end***/

/***traders css start***/

.masonry-s {
    padding: 25px;
}

.traders-section .custom-heading.ch-xssmall {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    max-width: 435px;
    margin: 0 auto;
    letter-spacing: 0.7px;
}

.my-masonry-grid {
    display: flex;
    width: auto;
    gap: 20px;
    justify-content: center;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

/* Add custom styles for each card */
.my-masonry-grid .card {
    border-radius: 12px;
    padding: 20px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    background: #FFFFFF1C;
    backdrop-filter: blur(42px);
    margin: 0 0 15px 0;
}

.my-masonry-grid .card img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-bottom: 0;
}

.my-masonry-grid .card h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    margin-bottom: 0;
}

.my-masonry-grid .card p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    letter-spacing: 0.5px;
}

.my-masonry-grid_column:nth-child(odd) {
    display: flex;
    flex-direction: column;
}

.ts-inner {
    display: flex;
    align-items: center;
    gap: 12px;
}

/***traders css End***/


/***faq css start***/
.accordion {
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
}

.accordion-item {
    background: transparent;
    border-width: 1px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(80, 106, 131, 0) 3.5%, rgba(117, 234, 139, 0.5) 28%, rgba(117, 234, 139, 0.5) 68.5%, rgba(80, 106, 131, 0.13) 96.5%);
    border-image-slice: 1;
    padding: 23px 0;
    border-right: none;
}

.accordion-title {
    padding: 10px 0;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    color: #ffffff;
}

.accordion-item:first-child {
    border-top: none;
}

/* .accordion-title:hover {
    background-color: #eaeaea;
  } */

.accordion-icon {
    font-size: 22px;
    color: #75EA8B;
    width: 16.52px;
    height: auto;
    text-align: center;
}

.accordion-content {
    background-color: transparent;
    padding: 0 0 0 0;
    font-size: 16px;
    color: #fff;
    transition: max-height 0.3s ease-out;
    letter-spacing: 0.8px;
}

/***faq css end***/

/***customersupportcss start****/
/* CommunitySection.css */

.community-section {
    /* background: linear-gradient(to bottom, #0b3d20, #000000); */
    color: white;
    text-align: center;
    padding: 60px 20px;
    max-width: 800px;
    margin: 0 auto;
    /* display: inline-block; */
}

.community-section .stats {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    max-width: 490px;
    margin: 0 auto;
    width: 100%;
    gap: 90px;
    position: relative;
    padding-bottom: 5px;
}

.community-section .stat-item:after {
    position: absolute;
    content: "";
    border: 1.5px solid;
    border-image-source: linear-gradient(180deg, rgba(80, 99, 118, 0) 26.14%, #89B4C0 60.21%, rgba(0, 0, 0, 0) 94.97%);
    border-image-slice: 1;
    border-width: 1px;
    border-style: solid;
    height: 68px;
    right: -43px;
    top: -10px;
}

.community-section .stat-item:last-child:after {
    display: none;
}

.community-section .stat-item {
    text-align: center;
    position: relative;
}

.community-section .stat-value {
    font-size: 22px;
    font-weight: 500;
    line-height: 26.63px;
    text-align: center;

}

.community-section .stat-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #A4D5E2;
    margin-bottom: 0;
}




.lets-talk .lt-heading {
    font-size: 197px;
    font-weight: 600;
    line-height: 242.05px;
    text-align: left;
    background: linear-gradient(90deg, #75EA8B 5.69%, #FFFFFF 40.94%, #FFFFFF 68%, #FFFFFF 98.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    white-space: nowrap;
}

.lt-inner {
    background: #00091338;
    border: 1px solid #FFFFFF26;
    backdrop-filter: blur(22px);
    border-radius: 14px;
    max-width: 1164px;
    padding: 25px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.lt-inner:before {
    /* position: absolute;
    content: "";
    border-width: 2.5px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(80, 106, 131, 0) 3.5%, rgba(117, 234, 139, 0.5) 28%, rgba(117, 234, 139, 0.5) 68.5%, rgba(80, 106, 131, 0.13) 96.5%);
    border-image-slice: 1;
    width: 500px;
    left: 60px;
    top: 24px; */

    content: '';
    z-index: -2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 200%;
    height: 40%;
    border-radius: 50%;
    background: conic-gradient(rgba(0, 0, 0, 0) 0%, #75EA8B 25%, rgba(0, 0, 0, 0) 50%, #75EA8B 75%, rgba(0, 0, 0, 0) 100%);
    animation: rotateGlow 30s linear infinite;
    filter: blur(22px);
}

.lt-inner:after {
    /* position: absolute;
    content: "";
    border-width: 2.5px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(80, 106, 131, 0) 3.5%, rgba(117, 234, 139, 0.5) 28%, rgba(117, 234, 139, 0.5) 68.5%, rgba(80, 106, 131, 0.13) 96.5%);
    border-image-slice: 1;
    width: 500px;
    top: auto;
    bottom: -2px;
    border-right: none;
    right: 50px; */
    content: '';
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #000000;
    border-radius: 14px;
}

.lt-inner-bx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
}

.gss-inner {
    margin-top: 45px;
}

/***customersupportcss End****/



.mask-section {
    opacity: 1 !important;
}


.scroll-class {
    opacity: 1;
}

.hib-box.box-left {
    text-align: left;
}

.hib-box.box-right {
    text-align: left;
}

.hib-box.box-left.one {
    position: relative;
    /* left: -200px; */
}

.hib-box.box-right.two {
    position: relative;
    /* right: -200px; */
}


.hib-box.box-left.three {
    position: relative;
    /* left: -200px; */
}


.hib-box.box-right.four {
    position: relative;
    /* right: -200px; */
}


.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
}



.dots-navigation {
    margin-top: 20px;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.active {
    background-color: #007bff; /* Highlight color for the active dot */
}


.my-masonry-grid.vsdfgbsrhgsrhbsrh{
    justify-content: inherit;
    overflow: hidden;
    overflow-x: scroll;
}

.my-masonry-grid.vsdfgbsrhgsrhbsrh::-webkit-scrollbar{
    display: none;
}

.my-masonry-grid .set{
    width: 320px;
    flex: 0 0 auto;
}

.my-masonry-grid .set > :last-child{
    margin-bottom: 0;
}



.csdgvsrgf{
    position: relative;
    padding-top: 15%;
}

.csdgvsrgf .xsfvsgrsg{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}


.oa-new-design-bx{
    display: flex;
    justify-content: center;
}

.oa-new-design-bx .andb-item{
    border-bottom: 1px solid #fff3;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    padding: 0;
}