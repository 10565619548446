:root {
  --white: #ffffff;
  --black: #000000;
  --red: #be3144;
  --blue: #45567d;
  --gray: #303841;
  --main-florescent-green: #75ea8b;
  --gainsboro: #ffffff2b;
  --mediumAquamarine: #75EA8B;
  --medium-aquamarine: #5fc371;
  --gray8-color: #141414;
  --medium-Aquamarine: #75EA8B;
  --green-or-blue: #4ca5c01f;
}

body {
  margin: 0;
  padding: 0 !important;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--black);
  font-family: "Inter", sans-serif;
}

.container-best-plan {
  max-width: 1330px;
}

.container-lg.cl-custom {
  max-width: 1300px;
}

/* --- Website Fonts --- */
@font-face {
  font-family: "Inter", sans-serif;
  src: url("../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
  font-display: swap;
}

/* --- Common Button Css Start --- */

.cbi-gap-10px {
  gap: 10px;
}

.common-btn-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 36px;
  padding: 0 15px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cbi-small {
  font-size: 12px;
  height: 32px;
  min-width: auto;
  padding: 0 10px;
}


.common-btn-item.cbi-fill {
  color: white;
  background-color: var(--mediumAquamarine);
  border-color: var(--mediumAquamarine);

}


.common-btn-item.cbi-outline {
  color: var(--white);
  border-color: var(--white);
}

.common-btn-item.cbi-enquiry {
  color: var(--white);
  border-color: var(--mediumAquamarine);
  padding: 0 30px;
}

.common-btn-item.cbi-enquiry span a {
  color: var(--white);
}

.common-btn-item.cbi-enquiry:hover {
  color: var(--black);
  background-color: var(--mediumAquamarine);
  border-color: var(--mediumAquamarine);
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.common-btn-item.cbi-outline.cbi-bg-white {
  color: var(--black);
  background-color: var(--white);
}


@media only screen and (min-width: 768px) {

  .common-btn-item.cbi-fill:hover {
    background-color: var(--medium-aquamarine);
    border-color: var(--medium-aquamarine);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

  .common-btn-item.cbi-outline:hover {
    color: white;
    background-color: var(--mediumAquamarine);
    border-color: var(--mediumAquamarine);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }

}

@media only screen and (max-width: 392px) {

  .common-btn-item {
    font-size: 12px;
    height: 32px;
    min-width: auto;
    padding: 0 10px;
  }

}

.smb-menu-bx .common-btn-item {
  padding: 0px 50px;
}


.mh-left .mh-logo-bx img:nth-child(1) {
  display: block;
}

.mh-left .mh-logo-bx img:nth-child(2) {
  display: none;
}


@media screen and (max-width: 460px) {
  .mh-left .mh-logo-bx img:nth-child(2) {
    display: block;
    height: 50px;
    position: relative;
    top: 1px;
    width: 50px;
  }

  .mh-left .mh-logo-bx img:nth-child(1) {
    display: none;
  }
}

/* --- Common Button Css End --- */


/* --- AML Policy section css start --- */

.common-heading-bx ul {
  font-size: 15px;
  line-height: 2.2;
  color: #bdbdbd;
  text-align: justify;
}

/* --- AML Policy section css end --- */




/* --- Common Heading Bx Css Start --- */
.common-heading-bx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  position: relative;
}


.common-heading-bx .common-heading {
  font-size: 42px;
  color: #000;
  font-weight: 700;
  line-height: normal;
}

.common-heading-bx .common-heading.mont-font {
  font-weight: 700;
}

.common-heading-bx .common-heading.ch-32 {
  font-size: 32px;
}

.common-heading-bx .common-heading.ch-big {
  font-size: 52px;
}

.common-heading-bx .common-sub-heading {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
}

.common-heading-bx .common-sub-heading.csh-ls-3px {
  letter-spacing: 3px;
}

.common-heading-bx .common-heading.ch-color-gradient {
  color: var(--medium-Aquamarine);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700;
}

.mine-section-sticky .common-heading-bx .common-heading {
  font-size: 42px;
}

@media screen and (max-width:992px) {
  .center-head-sub {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .just-btn-cent {
    justify-content: center !important;
  }
}

@media screen and (max-width:1100px) {
  .common-heading-bx .common-heading.ch-big {
    font-size: 55px;
  }

  .mine-section-sticky .common-heading-bx .common-heading {
    font-size: 42px;
  }

}

@media screen and (max-width:1024px) {

  .mine-section-sticky .common-heading-bx .common-heading,
  .common-heading-bx .common-heading.ch-32,
  .common-heading-bx .common-heading {
    font-size: 28px;
  }

}

@media screen and (max-width:992px) {
  .common-heading-bx .common-sub-heading.csh-ls-3px {
    letter-spacing: normal;
  }

}

@media screen and (max-width:767px) {

  /* .main-section{  
          text-align: center;
    } */

  .common-side-img-tr,
  .common-side-img,
  .main-section .common-side-img {
    max-width: 420px;
    margin: auto;
    display: block;
    width: 100%;
  }

  /* .common-heading-bx .common-heading br{
      display: none;
    } */

  .main-section .common-heading-bx .common-heading br {
    display: block;
  }

  .common-heading-bx .common-sub-heading {
    max-width: 100% !important;
  }

}

@media screen and (max-width: 776px) {
  .common-heading-bx .common-heading.ch-big {
    font-size: 50px;
  }

  .mine-section-sticky .common-heading-bx .common-heading {
    font-size: 32px;
  }
}

@media screen and (max-width: 650px) {
  .common-heading-bx .common-heading.ch-big {
    font-size: 40px;
  }

  .mine-section-sticky .common-heading-bx .common-heading {
    font-size: 32px;
  }
}

@media screen and (max-width:576px) {

  .mine-section-sticky .common-heading-bx .common-heading,
  .common-heading-bx .common-heading.ch-big {
    font-size: 27px;
  }

  .common-heading-bx .common-heading.ch-32,
  .common-heading-bx .common-heading {
    font-size: 21px;
  }

  .common-heading-bx .common-sub-heading {
    font-size: 16px;
  }
}

@media screen and (max-width:400px) {
  .common-heading-bx .common-heading.ch-big {
    font-size: 25px;
  }
}

@media screen and (max-width:360px) {
  .common-heading-bx .common-heading.ch-big {
    font-size: 23px;
  }

  .common-heading-bx .common-heading.ch-32,
  .common-heading-bx .common-heading {
    font-size: 17px;
  }
}

.common-side-img {
  width: 300px;
  /* -webkit-filter: drop-shadow(0 0 .10rem grey);
                filter: drop-shadow(0 0 .10rem grey); */
  display: block;
  margin: auto;
}

.common-side-img-tr {
  width: 350px;
  /* -webkit-filter: drop-shadow(0 0 .10rem grey);
                filter: drop-shadow(0 0 .10rem grey); */
  display: block;
  margin: auto;
}

@media screen and (max-width:576px) {

  .common-side-img-tr,
  .common-side-img {
    width: 200px;
  }
}

.register-icon {
  margin: auto;
  display: block;
  margin-bottom: 15px;
  width: 150px;
  height: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}


.bsrbhrshb {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  margin: auto;
  display: block;
}


.gfsrhdrh {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}


.sec-enabling-top {
  position: relative;
  top: 30px;
}

.main-section-img {
  display: inline;
  width: 100%;
  margin-top: -260px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-filter: opacity(50%);
  filter: opacity(50%);
  mix-blend-mode: lighten;
}

@media screen and (max-width:576px) {
  .main-section-img {
    margin-top: -100px;
  }
}

/* .main-section {
  position: relative;
  background: -webkit-linear-gradient(316.8deg, #002f2b, #000000, #002f2b);
  background: -moz-linear-gradient(316.8deg, #002f2b, #000000, #002f2b);
  background: -o-linear-gradient(316.8deg, #002f2b, #000000, #002f2b);
  background: linear-gradient(0.37turn, #002f2b, #000000, #002f2b);
} */

.main-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/trading/night-sky-with.png?v=1');
  opacity: 0.5;
  /* Adjust opacity for background image */
  /* position: relative; */
  display: none;
}


.mt-85 {
  margin-top: 85px;
}

.panel-group {
  position: relative;
}

.mt-110 {
  margin-top: 110px;
}

.trading-color {
  font-family: Montserrat;
  background: -webkit-linear-gradient(45deg, #f6a500, #913515 45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-Enquiry {
  border: 1px solid #913515 !important;
  background-color: white !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 4px 17px;
}

.btn-viewmore {
  border: 1px solid #913515 !important;
  background-color: #eae2c7 !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 4px 17px;
}

.btn-signin {
  border: 1px solid #913515 !important;
  background-color: #eae2c7 !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 4px 17px;
}

.btn-Dashboard {
  color: white;
  border: 1px solid #f6a500;
  background-color: #f6a500;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding: 4px 17px;
}

.dashboard-button {
  margin-top: 50px;

}

.dashboard-button button {
  padding: 5px 14px;
  margin: 0px 8px;
}

.ht-74 {
  font-size: 74px;
}

.ht-69 {
  font-size: 69px;
  line-height: 70px;
}

.ht-45 {
  font-size: 45px;
  line-height: 50px;
}

.img-w-440 {
  width: 440px;
  margin-left: -90px;
}

.btn-Trading {
  text-align: center;
  color: white;
  border: 1px solid #f6a500;
  background-color: #f6a500;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding: 6px 40px;
  margin: auto;
  display: block;
  font-weight: 500;
}


.img-w-410 {
  margin-top: 80px;
  /* width: 100%; */
  margin-left: -70px;
}

.img-w-350 {
  /* margin-top: 70px; */
  width: 350px;
}

.img-shadow {
  -webkit-filter: drop-shadow(0 0 .49rem grey);
  filter: drop-shadow(0 0 .49rem grey);
}

.ht-40_1 {
  font-size: 40px;
  line-height: 45px;
}

.ht-40 {
  font-size: 40px;
  line-height: 45px;
  /* padding: 0px 0 40px 111px; */
  padding: 0;
  margin: auto;
  margin-bottom: 60px;
  max-width: 578px;
}

.faq-color {
  background: -webkit-linear-gradient(45deg, #f6a500, #913515 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faqs-label {
  color: var(--medium-Aquamarine);
  font-size: 16px;
  font-weight: 600;
}

.capital-mb {
  margin-top: 160px;
  margin-bottom: 70px;
}

/* .accordion a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    color: black;
    font-size: 1.15rem;
    font-weight: 400;
    border-bottom: 1px solid #e5e5e5;
}

.accordion a:hover,
.accordion a:hover::after {
    cursor: pointer;
    color: #913515;
}

.accordion a:hover::after {
    border: 1px solid #03b5d2;
}

.accordion a.active {
    color: rgb(0, 0, 0);
    border-bottom: 2px solid rgb(255, 255, 255);
    font-weight: bold;
    text-decoration: none;
}

.accordion a:before {
    content: "";
    position: absolute;
    right: 100%;
    width: 30px;
    height: 30px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
} */

/* .accordion-link-info:before {
          background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/58/Info_icon.png");
        }
         */
/* .accordion a::after {
    content: "+";
    position: absolute;
    float: right;
    left: -2rem;
    font-size: 1.5rem;
    color: #913515;
    padding: 7px 4px;
    width: 24px;
    height: 24px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #f6a500;
    text-align: center;
    line-height: 0.2rem;
} */

/* .accordion a.active::after {
          content: "-";
          color: red;
          border: 1px solid red;
        } */

/* .accordion .content {
    opacity: 0;
    padding: 0 1rem;
    max-height: 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    clear: both;
    -webkit-transition: all 0.2s ease 0.15s;
    -o-transition: all 0.2s ease 0.15s;
    -moz-transition: all 0.2s ease 0.15s;
    transition: all 0.2s ease 0.15s;
} */

/* .accordion .content p {
          font-size: 1rem;
          font-weight: 300;
        } */

/* .accordion .content.active {
    opacity: 1;
    padding: 1rem;
    max-height: 100%;
    -webkit-transition: all 0.35s ease 0.15s;
    -o-transition: all 0.35s ease 0.15s;
    -moz-transition: all 0.35s ease 0.15s;
    transition: all 0.35s ease 0.15s;
} */

.register-border {
  border: 2px solid #ed9b00;
}

.register-border span {
  border: 2px solid #ed9b00;
}

.register-box {
  margin: 30px 53px;
  display: block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 10px;
}

.register-box img {
  margin: auto;
  display: block;
}

.register-box span {
  width: 45px;
  height: 45px;
  position: absolute;
  font-weight: bold;
  display: block;
  margin: 0px 42px 0px 42px;
  font-size: 30px;
  top: 147px;
  padding: 10px;
  text-align: center;
  padding: 0px 10px 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: white;
  z-index: 9999999;
}

.btn {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  -webkit-background-size: 200% auto;
  -moz-background-size: 200% auto;
  -o-background-size: 200% auto;
  background-size: 200% auto;
  color: black !important;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btn:hover {
  background-position: right center;
  /* change the direction of the change here */
}

.btn-5 {
  /* background-image: linear-gradient(to right, #e18f04 0%, #8f3415 51%, #e18f04 100%); */
  /* background-image: linear-gradient (180deg, #e18f04, #8f3415) !important; */
  background-color: #e18f04;
  color: white !important;
  border: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;700&display=swap');

.wrapper {
  width: 100%;
  margin: auto;
  background-color: #eae2c7;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, .1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, .1);
}

.buttonWrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #ed9b00;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  overflow: hidden;
  overflow-x: auto;
  padding: 5px;
}

button {
  /* letter-spacing: 3px; */
  border: none;
  padding: 10px;
  background-color: #eae2c7;
  color: #232c3d;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

/* button:hover {
            background-color: #d5e3ff;
            } */

button.active {
  background-color: white;
}

.conta-max-wid .active1 {
  border: 4px solid var(--white);
}

.tradingperiod {
  border: 4px solid;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 15px;
}

.tradingperiod {

  cursor: pointer;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 15px;
}

.content {
  /* display: none; */
  padding: 10px 15px;
}

.content .btn-funded {
  display: none;
}


.content.active .btn-funded,
.content.active {
  display: block;
}


.contentWrapper {
  padding: 10px;
  margin-top: 12px;
  border: 2px solid #a15535;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.btn-trading-period {
  width: 140px;
  padding: 5px 2px;
  font-size: 23px;
  border: 2px solid #a15535;
  margin: 0;
  font-weight: bold;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: #ec9a00;
  margin: auto;

  font-weight: 600;
}


.btn-trading-period>div {
  pointer-events: none;
}

.btn-trading-period div:last-child {
  display: none;
}

.period-btn {
  border: 2px solid #a15535;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding: 4px;
}

.margnlr {
  margin: 5px 50px;
}

.arrows-icon {
  position: absolute;
  right: 1px;
  top: 51px;
  left: 265px;
  width: 50px;
}

.colot-gray {
  color: var(--white);
}







/* */

.panel-heading {
  font-family: Montserrat;
}


.panel-default>.panel-heading {
  position: relative;
  color: #333;
  /* background-color: #fff; */
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.panel-default>.panel-heading span {
  display: block;
  padding: 10px 35px;
  color: var(--white);
}

.panel-default>.panel-heading>.panel-title span:after {
  content: "";
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url(../img/icon-png/pluscati.png);
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  height: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 27%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  width: 22px;
}

/* .panel-default>.panel-heading span:after {
                  background-image: url(../img/icon-png/Plus.png);
                  /* content: ""; */
/* padding: 1px 0px 0px 5px;
                  font-size: 25px;
                  border-radius: 50%;
                  width: 30px;
                  color: #913515;
                  height: 30px;
                  margin: -5px 0px 0px 0px;
                  position: relative;
                  top: 3px;
                  display: inline-block;
                  font-family: 'Glyphicons Halflings';
                  font-style: normal;
                  font-weight: 400;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  float: left;
                  left: -21px;
                  transition: transform .25s linear;
                  -webkit-transition: -webkit-transform .25s linear; */
/* } */

/* .panel-default>.panel-heading span[aria-expanded="true"] {
                  background-color: #eee;
                } */

.panel-default>.panel-heading span[aria-expanded="true"]:after {
  background-image: url(../img/icon-png/multiplication.png);
  /* content: "\00D7"; */
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading span[aria-expanded="false"]:after {
  /* background-image: url(../img/icon-png/Plus.png); */
  /* content: "\002b"; */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.panel-default {
  border-bottom: 1px solid white;
  margin: 10px 0px;
  padding: 10px 0px;
}

.main-heading {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 20px;
  text-transform: capitalize;
  padding: 4px 0;
  /* margin-left: 12px; */
}

.panel-body p strong {
  color: #857e7a;
}

.panel-body ul li {
  color: #898581 !important;
}

.border-faq {
  border-top: 2px solid white;
  margin: 15px 0px;
}

.panel-body p {
  color: #c3c3c3;
  margin: 0;
  padding: 4px 18px;
  margin-left: 12px;
  font-size: 15px;
  text-align: justify;
}

.panel-body ul {
  list-style: decimal;
  margin-left: 20px;
  margin-top: 10px;
  padding: 0 0 0 10px;
}

.panel-body ul li {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  margin: 10px 18px 0px 33px;
  text-align: justify;
  color: #898581;
}

.border-verify {
  border: 2px solid #ce7812;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 40px;
  margin: auto;
}

.border-register {
  border: 2px solid #eb9a03;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 40px;
  margin: auto;
}

.border-fund {
  border: 2px solid #b45913;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 40px;
  margin: auto;
}

.border-trade {
  border: 2px solid #9b3f15;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 40px;
  margin: auto;
}

.btn-view {
  display: block;
  margin: auto;
  margin-top: 30px;
}

.faq-mt {
  margin-top: 12%;
}

.text-fs {
  font-size: 18px;
}

.main-section-one {
  z-index: 1;
  position: relative;
}

.tradingaccount-font-21 {
  font-size: 21px;
}

.common-sub-content {
  font-size: 16px;
  color: #bdbdbd;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.common-sub-content p {
  font-size: 16px;
  color: #898581;
  margin: 0;
  padding: 0;
  text-align: justify;
  margin-bottom: 15px;
}

.please-email {
  position: relative;
  color: #8c8785;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  margin-right: 0;
  margin-top: 0px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.please-email:hover {
  color: var(--white);
  text-decoration: underline;
}

.pricing-account-size {
  font-weight: 700;
  font-size: 16px;
  position: relative;
  color: var(--white);
}


.pricing-info-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  right: -7px;
  top: -1px;
}

.pricing-info-icon svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.content-text {
  font-weight: 500;
  font-size: 16px;
}


.content-warning-icon {
  display: none;
}

.active .pricing-icon {
  display: block;
}


.signinup-group {
  margin: 0 0 20px;
  position: relative;
}

.sec-img-enaling {
  background-image: url('../img/trading/footer-img.jpg');
}

.signinup-group .group__label {
  color: var(--color1);
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 -2px 5px;
}

.form-error-msg {
  color: #983a25;
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -5px !important;
  margin-top: 2px;
  text-align: right;
}

div.error {
  clear: left;
  margin-left: 5.3em;
  color: red;
  padding-right: 1.3em;
  height: 100%;
  padding-bottom: 0.3em;
  line-height: 1.3;
}

.input-error {
  background: #ff9;
  border: 1px solid red;
}

.success_msg {
  width: 350px;
  line-height: 40px;
  border: 1px solid green;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: rgba(213, 255, 187, 0.7);
  display: none;
  position: absolute;
  bottom: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;
  text-align: center;
}

#p1,
#p2,
#p3,
#p4,
#p5 {
  color: red;
  font-size: 12px;
}

.signinup-group.error-group input,
.signinup-group.error-group select,
.signinup-group.error-group textarea {
  border-color: #f44336;
}

.widthfull {
  font-size: 16px;
  height: 44px !important;
  margin: auto;
  max-width: 450px;
  width: 100%;
}

.common-btn-item {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #0000;
  border: 1px solid #0000;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  color: black;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 1px;
  padding: 0 15px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.contact-info .contact-text h6 {
  color: black;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.73;
  margin: 0;
  overflow: hidden;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.contact-info {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin: 5px 0 !important;
  margin: 0;
  overflow: hidden;
  padding: 15px 10px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.contact-info .contact-icon {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: .8;
  padding: 2px;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 40px;
}


.vsdfhbsrxfhbrdf {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  background: #eae2c7;
  padding-bottom: 15px;
  padding-top: 15px;
}

/* --- Contact Page Css Start --- */
.contact-info {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
  margin: 5px 0 !important;
  margin: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.heading-50 {
  font-size: 50px !important;
}

.contact-info::after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  inset: 0;
  z-index: -1;
  opacity: 0;
  background-color: #ffffff07;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.contact-info:hover::after {
  opacity: 1;
}

.signinup-group input,
.signinup-group select,
.signinup-group textarea {
  /* background-color: white; */
  background-color: transparent;
  border: 1px solid #898581;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  color: #d3d3d3;
  display: block;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 10px 10px 10px 20px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  width: 100%;
}

.signinup-group textarea {
  background-color: var(--white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.signinup-group input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group input::placeholder {
  color: rgba(255, 255, 255, 0.658);
}

.signinup-group textarea:not(:-moz-placeholder-shown),
.signinup-group select:not(:-moz-placeholder-shown),
.signinup-group input:not(:-moz-placeholder-shown) {
  border-color: var(--mediumAquamarine);
}

.signinup-group textarea:not(:-ms-input-placeholder),
.signinup-group select:not(:-ms-input-placeholder),
.signinup-group input:not(:-ms-input-placeholder) {
  border-color: var(--mediumAquamarine);
}

.signinup-group textarea:focus,
.signinup-group textarea:not(:placeholder-shown),
.signinup-group select:focus,
.signinup-group select:not(:placeholder-shown),
.signinup-group input:focus,
.signinup-group input:not(:placeholder-shown) {
  border-color: var(--mediumAquamarine);
}

.contact-info .contact-icon {
  padding: 2px;
  width: 40px;
  height: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
}

.contact-info:hover .contact-icon {
  opacity: 1;
}

.contact-info .contact-icon img {
  width: 100%;
}

.contact-info .contact-text h6 {
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.73;
  overflow: hidden;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.contact-info .contact-text p {
  color: #898581;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 3px 0px;
}

.contact-info:hover .contact-text p {
  color: var(--white);
  text-decoration: underline;
}

.common-btn-item-contact.cbi-outline:hover {
  color: white;
  background-color: #e18f04;
  border-color: #e18f04;
}

.common-btn-item-contact.cbi-outline {
  color: #000;
  border-color: #933819;
}

.common-btn-item-contact {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #0000;
  border: 1px solid #0000;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  color: black;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 1px;
  padding: 10 15px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: -webkit-fill-available;
  margin: auto;
}

/* --- Contact Page Css End --- */

/* --- Breadcrumb Css Start --- */
.breadcrumb-bx {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.modal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.modal-content.custom-content {
  background-color: #0000008a;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.custom-modal-header {
  padding: 25px 15px 10px;
  position: -webkit-sticky;
  position: sticky;
}

.custom-modal-header .cmh-lable {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.modal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.modal-close-icon:hover {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.modal-close-icon {
  background: #0000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 2px 4px 12px #0000;
  -moz-box-shadow: 2px 4px 12px #0000;
  box-shadow: 2px 4px 12px #0000;
  color: var(--white);
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  padding: 4px;
  position: absolute;
  right: 10px !important;
  top: 10px;
  -webkit-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 35px;
}

.textlayout-main-bx {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 25px 0;
}

.breadcrumb-bx .breadcrumb-link {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
  color: var(--white);
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active {
  font-weight: 500;
  color: #d3d3d3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  pointer-events: none;
  text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover {
  color: var(--medium-Aquamarine);
  cursor: pointer;
}

.modal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #ffffff5e;
}

.has-error {
  color: red;
}

.signinup-group .has-error input,
.has-error textarea {
  border: 1px solid red;
}

/* --- Breadcrumb Css End --- */

/* === Blogs item css start === */
.blog-item {
  cursor: pointer;
  color: #898581;
  font-size: 14px;
  padding-bottom: 10px;
  display: block;
}

.blog-item:hover {
  color: #c5c5c5;

}

.blog-item .blog-imgs {
  position: relative;
  padding-top: 60%;
  margin-bottom: 15px;
  /* border-radius: 24px; */
  overflow: hidden;
}

.blog-item .blog-imgs>img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: top;
  z-index: 1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 10px 10px 0px 10px;
}

.blog-item .blog-imgs span {
  position: absolute;
  right: -1px;
  bottom: -1px;
  background-color: #fafafa;
  width: 70px;
  height: 70px;
  padding: 18px;
  z-index: 3;
  -webkit-border-top-left-radius: 16px;
  -moz-border-radius-topleft: 16px;
  border-top-left-radius: 16px;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  -o-transition: -o-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  -moz-transition: transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1), -moz-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1), -moz-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1), -o-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.blog-item .blog-imgs span::after {
  content: " ";
  position: absolute;
  right: -webkit-calc(100% - 11px);
  right: -moz-calc(100% - 11px);
  right: calc(100% - 11px);
  bottom: -11px;
  width: 70px;
  height: 70px;
  /* background-image: url(/img/icons/patten/patten01.svg); */
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.blog-item .blog-imgs span::before {
  content: " ";
  position: absolute;
  right: -11px;
  bottom: -webkit-calc(100% - 11px);
  bottom: -moz-calc(100% - 11px);
  bottom: calc(100% - 11px);
  width: 70px;
  height: 70px;
  /* background-image: url(/img/icons/patten/patten01.svg); */
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.blog-item:hover .blog-imgs span {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

.blog-item .blog-imgs span img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.blog-item .blog-imgs::after {
  content: "";
  position: absolute;
  inset: 1;
  opacity: 0;
  z-index: 2;
  background-color: black;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blog-item:hover .blog-imgs::after {
  opacity: 0.2;
}

.blog-item .blog-author {
  opacity: 0.5;
  font-weight: 500;
  margin-bottom: 5px;
}

.blog-item .blog-lable {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blog-item.our-work-item .blog-lable {
  -webkit-line-clamp: 2;
  font-size: 16px;
  line-height: 1.4;
  padding: 0px 10px;
  margin-bottom: 0;
}

.blog-item .blog-tags div {
  display: inline-block;
  background: #f0f0f0;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  padding: 5px 10px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

.blog-box {
  transition: all 0.3s ease-in-out;
}

.blog-box:hover {
  background-color: #01221f;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  /* border: 1px solid #FFFFFF4D; */
  background: #6A748E38;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.bg-img-blog {
  /* background-image: url('../img/blog/bg1.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-filter: opacity(50%);
  filter: opacity(50%);
  mix-blend-mode: lighten;
}

@media (min-width: 992px) {
  .main-phrase {
    font-size: 95px;
    line-height: 95px;
    min-height: 190px;
    letter-spacing: -6px;
    margin-top: 30px;
    color: #fff;
    text-align: center;
  }
}

.subtitle {
  font-size: 14px;
  letter-spacing: 1px;
  margin: 28px 0;
  text-align: center;
  color: #fff;
}

.countdown-row {
  display: block;
  text-align: center;
}

.countdown-section {
  border-left: 1px dotted #dadada;
  border-right: 1px dotted #dadada;
  border-color: rgba(218, 218, 218, 0.8);
  color: #dadada;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 0px 0px 0px;
  text-align: center;
  width: 25%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.social {
  margin: 0px 0;
}

.social {
  text-align: center;
  margin-top: 20px;
}

.countdown-amount {
  color: #fff;
  display: block;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1;
  padding-bottom: 10px;
}

.main-sect-blog {
  padding: 50px;
}

.common-details-title {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 1.4 !important;
  text-align: center;
}

.common-detai-jus {
  font-size: 16px;
  text-align: justify;
}

.details-link {
  color: #d3d3d3 !important;
}

.details-link:hover {
  color: var(--medium-Aquamarine) !important;
}

@media only screen and (max-width: 680px) {
  .countdown-amount {
    font-size: 48px;
  }
}

@media only screen and (max-width: 480px) {
  .countdown-section {
    padding: 28px 6px 28px;
  }

  .countdown-amount {
    font-size: 42px;
  }

  .common-details-title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 360px) {
  .countdown-section {
    font-size: 10px;
    padding: 21px 6px;
  }

  .countdown-amount {
    font-size: 36px;
  }
}

/* Icons */

.social {
  text-align: center;
  margin-top: 20px;
}

.social a {
  display: inline-block;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: -webkit-transform;
  -moz-transition-property: transform, -moz-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform, -moz-transform, -o-transform;

}

.social a:hover {
  background: rgba(0, 0, 125, 0.3);
  border: 1px solid #030046;
  color: #fff;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.social li {
  display: inline-block;
  margin: 4px;
}

.icon,
.modal-toggle .icon {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  width: 36px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon:hover,
.modal-toggle .icon:hover {
  color: #fff;
}

.icon,
.modal-toggle .icon,
#bg-video-controls .fa {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  width: 36px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon:hover,
.modal-toggle .icon:hover,
#bg-video-controls .fa:hover {
  color: rgba(0, 0, 125, 0.3);
}

.row h2 .icon {
  font-size: 18px;
  line-height: 1.55556;
  margin-right: 10px;
}

a.button-about {
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 125, 0.0);
  padding: .7em 1.5em;
  outline: none;
  border: 1px solid #fff;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
}

a.button-about:hover {
  background: rgba(0, 0, 125, 0.3);
  border: 1px solid #030046;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
}

a.button-about:active {
  background: rgba(0, 0, 125, 0.3);
  border: 1px solid #030046;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
}

/* ModaL start */
.modal-coming-soon .modal-content {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}


/* ModaL start */
.modal-coming-soon .modal-content {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.modal-coming-soon .modal-body {
  /* background: url('/img/bg-img/stars.webp'), -webkit-linear-gradient(110deg, #002f2b, #000000, #002f2b) ;
    background: url('/img/bg-img/stars.webp'), -moz-linear-gradient(110deg, #002f2b, #000000, #002f2b) ;
    background: url('/img/bg-img/stars.webp'), -o-linear-gradient(110deg, #002f2b, #000000, #002f2b) ;
    background: url('/img/bg-img/stars.webp'), linear-gradient(-20deg, #002f2b, #000000, #002f2b) ; */
  -webkit-background-size: cover, 100% -webkit-calc(100% + 100px);
  -moz-background-size: cover, 100% -moz-calc(100% + 100px);
  -o-background-size: cover, 100% calc(100% + 100px);
  background-size: cover, 100% calc(100% + 100px);
  color: var(--white);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: 50px 20px;
}

.modal-coming-soon .modal-body .card-coming-head {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  letter-spacing: .5rem;
}


.modal-coming-soon .modal-body .card-coming-soon {
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#82d1e7), to(transparent));
  background: -webkit-linear-gradient(left, transparent, #82d1e7, transparent);
  background: -moz-linear-gradient(left, transparent, #82d1e7, transparent);
  background: -o-linear-gradient(left, transparent, #82d1e7, transparent);
  background: linear-gradient(0.25turn, transparent, #82d1e7, transparent);
  height: 1px;
  width: 80%;
  margin: auto;
}

.modal-coming-soon .modal-body p {
  color: #ffffffb0;
  text-align: center;
  padding: 20px 0 0;
}

.modal-coming-soon .modal-content .modal-close-icon {
  background: #0000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 2px 4px 12px #0000;
  -moz-box-shadow: 2px 4px 12px #0000;
  box-shadow: 2px 4px 12px #0000;
  color: #000000;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  padding: 4px;
  position: absolute;
  right: 10px !important;
  top: 10px;
  -webkit-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  -moz-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  width: 35px;
  z-index: 99;
  background: white;
}

/* ModaL end */


/* === Blogs item css End === */

mh-serach-bx-res,
.mh-serach-bx-responsive {
  display: none;
}


@media screen and (max-width:576px) {

  .mh-serach-bx-res.active,
  .mh-serach-bx-responsive.active {
    display: block;
    border: none;
  }
}

@media screen and (max-width:1024px) {
  .conta-max-wid {
    max-width: 1360px;
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width:776px) {
  .conta-max-wid {
    max-width: 1360px;
    width: 100%;
    margin: auto;
    padding: 0px 15px !important;
  }
}

.conta-max-wid {
  max-width: 1360px;
  width: 100%;
  margin: auto;
  padding: 0px 30px;
}


/*  -- section key Highlights start --  */

.mt-270 {
  margin-top: -270px;
}

.main-heading-key {
  color: var(--white);
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 20px;
  text-transform: capitalize;
  /* padding: 4px 18px; */
  margin-left: 12px;
}

.main-heading-key .main-title {
  position: relative;
  width: 187px;
  padding: 3px;
  text-align: center;
  background-color: #0d1315;
  border: 1px solid transparent;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  /* Same value as your border-radius */
  overflow: hidden;

}

.main-heading-key .main-title:before {
  content: "";
  position: absolute;
  display: block;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  border-radius: inherit;
  -webkit-transition: opacity .15s ease-in-out 0s;
  -o-transition: opacity .15s ease-in-out 0s;
  -moz-transition: opacity .15s ease-in-out 0s;
  transition: opacity .15s ease-in-out 0s;
  /* opacity: 0; */
  background-image: -webkit-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -moz-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -o-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: linear-gradient(272deg, #00998e, #ffffff, #ffbf58);
  z-index: 0;
}

.main-heading-key .main-title .main-hea-bor {
  position: relative;
  width: 179px;
  padding: 7px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-shadow: 0 0 10px #00000070;
  -moz-box-shadow: 0 0 10px #00000070;
  box-shadow: 0 0 10px #00000070;
  border: 1px solid rgb(63, 59, 69);
  -webkit-transition: border .15s ease-in-out 0s;
  -o-transition: border .15s ease-in-out 0s;
  -moz-transition: border .15s ease-in-out 0s;
  transition: border .15s ease-in-out 0s;
  background-color: #0d1315;
  z-index: 1;
}

.main-sub-hea-color-golad {
  color: #ffbf58;
  font-weight: 700;
  font-size: 33px;
}

.main-sub-hea-color-green {
  color: #3fb2aa;
  font-weight: 700;
  font-size: 33px;
}

.main-heading-key .sub-heading {
  font-size: 23px;
  font-weight: 700;
}

@media screen and (max-width:776px) {
  .main-heading-key .sub-heading {
    font-size: 24px;
  }

  .main-sub-hea-color-golad {
    font-size: 25px;
  }

  .main-sub-hea-color-green {
    font-size: 25px;
  }
}

@media screen and (max-width:992px) {
  .main-heading-key .sub-heading {
    font-size: 18px;
  }

  .main-heading-key .main-title {
    margin: auto;
  }

  .mt-200 {
    margin-top: -130px;
  }
}


@media screen and (max-width:576px) {
  .main-heading-key .sub-heading {
    font-size: 17px;
  }

  .main-sub-hea-color-golad {
    font-size: 21px;
  }

  .main-sub-hea-color-green {
    font-size: 21px;
  }

  .mt-200 {
    margin-top: -200px;
  }
}


@media screen and (max-width:476px) {
  .main-heading-key .sub-heading {
    font-size: 14px;
  }

  .main-sub-hea-color-golad {
    font-size: 19px;
  }

  .main-sub-hea-color-green {
    font-size: 19px;
  }
}

@media screen and (max-width:1100px) {
  .mt-270 {
    margin-top: 0px;
  }
}

/* --- Card Item Flip Bx Css Start --- */

.card-item-flip {
  overflow-x: auto;
  overflow-y: hidden;
}

.card-item-flip {
  padding-bottom: 0px;
  height: 100%;
  cursor: pointer;
}

.ctf-inner {
  position: relative;
  height: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.card-item-flip .card-item {
  /* border: 1px solid var(--mediumAquamarine); */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-item-flip .cif-befor {
  position: absolute;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-item-flip .cif-after {
  background-color: #a7a7a726;
  height: 100%;
}

.card-item-flip .card-item .card-i-img {
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
}

.card-item-flip .card-item .card-i-img.img-w-h-60px {
  width: 60px;
  height: 60px;
  margin: auto;
  display: block;
  margin-bottom: 15px;
}

.card-item-flip .ctf-inner .cif-befor {
  opacity: 1;
}

.aos-init .card-item-flip .ctf-inner .ctf-inner-height {
  height: 100%;
}

.benefitsslider-bx .slick-slide .card-item-flip {
  height: 219px;
}

.card-item .card-i-heading {
  margin-top: 5px;
  font-size: 16px;
  color: var(--white);
  font-weight: 700;
}

.card-item .card-i-text {
  margin-top: 5px;
  font-size: 14px;
  color: #979797;
}

.mt-bx-50m {
  margin-top: -50px;
}

.mt-bx-50p {
  margin-top: 50px;
}

@media screen and (max-width:664px) {
  .benefitsslider-bx .slick-slide .card-item-flip {
    height: 240px;
  }
}

@media screen and (max-width:576px) {
  .benefitsslider-bx .slick-slide .card-item-flip {
    height: 192px;
  }
}

@media screen and (max-width:411px) {
  .benefitsslider-bx .slick-slide .card-item-flip {
    height: 213px;
  }
}

@media screen and (max-width:331px) {
  .benefitsslider-bx .slick-slide .card-item-flip {
    height: 234px;
  }
}

.w-330 {
  max-width: 410px;
  display: block;
  margin: auto;
}

@media screen and (max-width:500px) {
  .w-330 {
    max-width: 374px;
  }

  .mt-200 {
    margin-top: -150px;
  }

}

@media screen and (max-width:400px) {
  .w-330 {
    max-width: 290px;
  }

  .mt-200 {
    margin-top: -100px;
  }
}

/* --- Card Item Flip Bx Css End --- */


/*  -- section key Highlights end --  */

/*  -- section choose challenge start --  */

.main-heading-choose {
  color: var(--white);
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 20px;
  text-transform: capitalize;
  /* padding: 4px 18px; */
  margin-left: 12px;
  position: relative;
}

.main-heading-choose .main-title {
  position: relative;
  width: 187px;
  padding: 3px;
  text-align: center;
  background-color: #0d1315;
  border: 1px solid transparent;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  /* Same value as your border-radius */
  overflow: hidden;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.main-heading-choose .main-title:before {
  content: "";
  position: absolute;
  display: block;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  border-radius: inherit;
  -webkit-transition: opacity .15s ease-in-out 0s;
  -o-transition: opacity .15s ease-in-out 0s;
  -moz-transition: opacity .15s ease-in-out 0s;
  transition: opacity .15s ease-in-out 0s;
  /* opacity: 0; */
  background-image: -webkit-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -moz-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -o-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: linear-gradient(272deg, #00998e, #ffffff, #ffbf58);
  z-index: 0;
}

.main-heading-choose .main-title .main-hea-bor {
  position: relative;
  width: 180px;
  padding: 7px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-shadow: 0 0 10px #00000070;
  -moz-box-shadow: 0 0 10px #00000070;
  box-shadow: 0 0 10px #00000070;
  border: 1px solid rgb(63, 59, 69);
  -webkit-transition: border .15s ease-in-out 0s;
  -o-transition: border .15s ease-in-out 0s;
  -moz-transition: border .15s ease-in-out 0s;
  transition: border .15s ease-in-out 0s;
  background-color: #0d1315;
  z-index: 1;
}

.main-heading-choose .sub-heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 50px;
  color: var(--main-florescent-green);
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: auto;
}

.main-heading-tradin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.img-40 {
  width: 40px;
}

@media screen and (max-width:776px) {
  .img-40 {
    width: 28px;
  }
}

.main-tit-tradi {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 31px;
  color: var(--white);
  position: absolute;
  right: 34px;
  top: 11px;
}

.sub-text {
  color: #ffffff8c;
  text-transform: math-auto;
}

.color-green-Shades {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#136e5d), to(#0c4a3e));
  background-image: -webkit-linear-gradient(top, #136e5d, #0c4a3e);
  background-image: -moz-linear-gradient(top, #136e5d, #0c4a3e);
  background-image: -o-linear-gradient(top, #136e5d, #0c4a3e);
  background-image: linear-gradient(180deg, #136e5d, #0c4a3e);
}

.color-globeartichoke {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5a6135), to(#464c29));
  background-image: -webkit-linear-gradient(top, #5a6135, #464c29);
  background-image: -moz-linear-gradient(top, #5a6135, #464c29);
  background-image: -o-linear-gradient(top, #5a6135, #464c29);
  background-image: linear-gradient(180deg, #5a6135, #464c29);
}

.color-green {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#215c40), to(#afffda));
  background-image: -webkit-linear-gradient(top, #215c40, #afffda);
  background-image: -moz-linear-gradient(top, #215c40, #afffda);
  background-image: -o-linear-gradient(top, #215c40, #afffda);
  background-image: linear-gradient(180deg, #215c40, #afffda);
}

.color-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#214f5d), to(#42a1bb));
  background-image: -webkit-linear-gradient(top, #214f5d, #42a1bb);
  background-image: -moz-linear-gradient(top, #214f5d, #42a1bb);
  background-image: -o-linear-gradient(top, #214f5d, #42a1bb);
  background-image: linear-gradient(180deg, #214f5d, #42a1bb);
}

.color-purple {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#46215c), to(#8d42bb));
  background-image: -webkit-linear-gradient(top, #46215c, #8d42bb);
  background-image: -moz-linear-gradient(top, #46215c, #8d42bb);
  background-image: -o-linear-gradient(top, #46215c, #8d42bb);
  background-image: linear-gradient(180deg, #46215c, #8d42bb);
}

.color-gray {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3f3f3f), to(#7f7f7f));
  background-image: -webkit-linear-gradient(top, #3f3f3f, #7f7f7f);
  background-image: -moz-linear-gradient(top, #3f3f3f, #7f7f7f);
  background-image: -o-linear-gradient(top, #3f3f3f, #7f7f7f);
  background-image: linear-gradient(180deg, #3f3f3f, #7f7f7f);
}


.tradingperiod .btn-funded {
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: rgb(41, 41, 41);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 7px 10px;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}



.tradingperiod .btn-funded:hover,
.tradingperiod.active1 .btn-funded {
  background-color: #fff;
  border-color: white;
  color: var(--black);
}



@media screen and (max-width:700px) {

  .main-heading-feedback .sub-heading,
  .main-heading-feedback .sub-heading,
  .main-heading-choose .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 42px;
  }
}

@media screen and (max-width:576px) {

  .main-heading-feedback .sub-heading,
  .main-heading-feedback .sub-heading,
  .main-heading-choose .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 35px;
  }
}

@media screen and (max-width:450px) {

  .main-heading-feedback .sub-heading,
  .main-heading-feedback .sub-heading,
  .main-heading-choose .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 27px;
  }
}

@media screen and (max-width:420px) {

  .main-heading-feedback .sub-heading,
  .main-heading-feedback .sub-heading,
  .main-heading-choose .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
  }
}

/*  -- section choose challenge end --  */

/*  -- section Our Capital challenge start --  */

.trader-capital-bg-color {
  background: -webkit-linear-gradient(271deg, #000000, rgb(0 24 22) 61.37%, #07031300);
  background: -moz-linear-gradient(271deg, #000000, rgb(0 24 22) 61.37%, #07031300);
  background: -o-linear-gradient(271deg, #000000, rgb(0 24 22) 61.37%, #07031300);
  background: linear-gradient(179deg, #000000, rgb(0 24 22) 61.37%, #07031300);
  height: 450px;
  width: 1351px;
  position: relative;
  bottom: 0;
  left: 0;
  margin-left: -122px;
  margin-top: -57px;
}


.main-heading-our {
  color: var(--white);
  font-weight: 600;
  margin-bottom: -5px;
  /* margin-top: -420px; */
  text-transform: capitalize;
  /* padding: 4px 18px; */
  margin-left: 12px;
  position: relative;
}

.main-heading-our .sub-heading {
  font-weight: 700;
  font-size: 50px;
  color: var(--main-florescent-green);
  text-align: center;
}


@media screen and (max-width:700px) {
  .main-heading-our .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 42px;
  }
}

@media screen and (max-width:576px) {
  .main-heading-our .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 35px;
  }
}

@media screen and (max-width:450px) {
  .main-heading-our .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 27px;
  }
}

@media screen and (max-width:420px) {
  .main-heading-our .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
  }
}

/*  -- section Our Capital challenge end --  */

/*  -- section Why Funded Firm start --  */

.whyfun-title-sub {
  color: #ffffff8c;
  text-transform: math-auto;
  font-weight: 500;
}

/* .card-guaranteed.cif-after,
  .card-besttrading.cif-after,
  .card-best-trading.cif-after,
  .card-fundedfirm.cif-after,
  .card-support.cif-after{
    background-image: linear-gradient(130deg, #a7a7a726, #a7a7a726);
  } */

.card-guaranteed {
  background-image: -webkit-linear-gradient(320deg, #215c40, #78d7ab);
  background-image: -moz-linear-gradient(320deg, #215c40, #78d7ab);
  background-image: -o-linear-gradient(320deg, #215c40, #78d7ab);
  background-image: linear-gradient(130deg, #215c40, #78d7ab);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.99); */
}

.card-guaranteed .card-item-showtext .card-item img {
  width: 150px;
  mix-blend-mode: luminosity;
}

.card-guaranteed .card-item-showtext .card-item .card-i-heading {
  margin-top: 5px;
  font-size: 28px;
  color: var(--white);
  font-weight: 500;
  max-width: 200px;
  width: 100%;
}

.card-guaranteed .card-item-showtext .card-item .card-i-text {
  margin-top: 5px;
  font-size: 16px;
  color: #dfdfdf;
  max-width: 185px;
  width: 100%;
}

.card-besttrading {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#46215c), to(#8d42bb));
  background-image: -webkit-linear-gradient(top, #46215c, #8d42bb);
  background-image: -moz-linear-gradient(top, #46215c, #8d42bb);
  background-image: -o-linear-gradient(top, #46215c, #8d42bb);
  background-image: linear-gradient(180deg, #46215c, #8d42bb);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.99); */
  margin-top: 1rem;
}

.card-besttrading .card-item-showtext .card-item img {
  mix-blend-mode: color-dodge;
}

.card-besttrading .card-item-showtext .card-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--white))
}

.card-besttrading .card-item-showtext .card-item .card-i-heading {
  margin-top: 5px;
  font-size: 25px;
  color: var(--white);
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.card-best-trading {
  height: 100%;
}

.card-best-trading {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#214f5d), to(#42a1bb));
  background-image: -webkit-linear-gradient(top, #214f5d, #42a1bb);
  background-image: -moz-linear-gradient(top, #214f5d, #42a1bb);
  background-image: -o-linear-gradient(top, #214f5d, #42a1bb);
  background-image: linear-gradient(180deg, #214f5d, #42a1bb);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.99); */
  height: 100%;
}

/* .card-best-trading .card-item-showtext .card-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  } */

.card-best-trading .card-item-showtext .card-item img {
  width: 350px;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-filter: hue-rotate(293deg);
  filter: hue-rotate(293deg);
  /* mix-blend-mode: color-burn; */
}

.card-best-trading .card-item-showtext .card-item .card-i-text {
  margin-top: 15px;
  font-size: 14px;
  --tw-text-opacity: 1;
  color: rgb(255 209 27 / var(--tw-text-opacity));
  font-weight: 500;
}

.card-best-trading .card-item-showtext .card-item .card-i-heading-sub {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
  top: -webkit-calc(100% - 412px);
  top: -moz-calc(100% - 412px);
  top: calc(100% - 412px);
}

.card-best-trading .card-item-showtext .card-item .card-i-sub-text {
  margin-top: 100px;
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
  max-width: 501px;
  width: 100%;
  position: absolute;
  top: -webkit-calc(100% + -360px);
  top: -moz-calc(100% + -360px);
  top: calc(100% + -360px);
}

.card-best-trading .card-item-showtext .card-item .card-i-heading {
  font-size: 25px;
  color: var(--white);
  font-weight: 500;
  top: -webkit-calc(100% - 462px);
  top: -moz-calc(100% - 462px);
  top: calc(100% - 462px);
}


.card-empowering {
  position: relative;
  background-color: #d2e5f11a;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.99); */
  z-index: 0;
}

.card-empowering .card-empowe-bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/trading/bg-img.png');
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-filter: opacity(50%);
  filter: opacity(50%);
  mix-blend-mode: lighten;
}

.card-empowering .card-item-showtext .card-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--white));
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-empowering .card-item-showtext .card-item video {
  width: 100%;
  /* height: 576px; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
}

.card-empowering .card-item-showtext .card-item .card-i-heading {
  margin-top: 80px;
  font-size: 24px;
  color: var(--white);
  font-weight: 500;
  z-index: 0;
  max-width: 337px;
  width: 100%;
}

.card-empowering .card-item-showtext .card-item .main-relative {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 41px;
  margin-bottom: 50px;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-heading {
  margin-top: 5px;
  font-size: 24px;
  color: var(--white);
  font-weight: 500;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-heading {
  margin-top: 5px;
  font-size: 24px;
  color: var(--white);
  font-weight: 500;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-heading {
  margin-top: 5px;
  font-size: 24px;
  color: var(--white);
  font-weight: 500;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-text {
  color: #dfdfdf;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-one {
  background: -webkit-linear-gradient(323deg, #214f5d, #3aa373);
  background: -moz-linear-gradient(323deg, #214f5d, #3aa373);
  background: -o-linear-gradient(323deg, #214f5d, #3aa373);
  background: linear-gradient(127deg, #214f5d, #3aa373);
  height: 55px;
  width: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-tow {
  background: -webkit-linear-gradient(323deg, #035f7b, #12bacc);
  background: -moz-linear-gradient(323deg, #035f7b, #12bacc);
  background: -o-linear-gradient(323deg, #035f7b, #12bacc);
  background: linear-gradient(127deg, #035f7b, #12bacc);
  height: 55px;
  width: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-three {
  background: -webkit-gradient(linear, left top, left bottom, from(#46215c), to(#8d42bb));
  background: -webkit-linear-gradient(top, #46215c, #8d42bb);
  background: -moz-linear-gradient(top, #46215c, #8d42bb);
  background: -o-linear-gradient(top, #46215c, #8d42bb);
  background: linear-gradient(180deg, #46215c, #8d42bb);
  height: 55px;
  width: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.card-empowering .card-item-showtext .card-item .main-relative .relative-four {
  background: -webkit-gradient(linear, left top, left bottom, from(#2d215c), to(#4283bb));
  background: -webkit-linear-gradient(top, #2d215c, #4283bb);
  background: -moz-linear-gradient(top, #2d215c, #4283bb);
  background: -o-linear-gradient(top, #2d215c, #4283bb);
  background: linear-gradient(180deg, #2d215c, #4283bb);
  height: 55px;
  width: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  left: 4px;
  top: 8px;
}

/* .m-empowering{
    margin: 25px 0px 0px 0px;
  } */

.card-fundedfirm {
  height: 100%;
}

.card-fundedfirm {
  background-image: -webkit-linear-gradient(323deg, #214f5d, #3aa373);
  background-image: -moz-linear-gradient(323deg, #214f5d, #3aa373);
  background-image: -o-linear-gradient(323deg, #214f5d, #3aa373);
  background-image: linear-gradient(127deg, #214f5d, #3aa373);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.95); */
  height: 100%;
}

.card-fundedfirm .card-item-showtext .card-item .card-i-heading {
  margin-top: 50px;
  font-size: 25px;
  color: var(--white);
  font-weight: 500;
  max-width: 200px;
  width: 100%;
}

.card-fundedfirm .card-item-showtext .card-item .card-i-text {
  margin-top: 15px;
  font-size: 16px;
  color: #dfdfdf;
  max-width: 185px;
  width: 100%;
}

.card-support {
  height: 100%;
}

.card-support {
  background-image: -webkit-linear-gradient(323deg, #035f7b, #12bacc);
  background-image: -moz-linear-gradient(323deg, #035f7b, #12bacc);
  background-image: -o-linear-gradient(323deg, #035f7b, #12bacc);
  background-image: linear-gradient(127deg, #035f7b, #12bacc);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* transform: scale(.95); */
  height: 100%;
}

.card-support .card-item-showtext .card-item .card-i-heading {
  margin-top: 50px;
  font-size: 25px;
  color: var(--white);
  font-weight: 500;
  max-width: 200px;
  width: 100%;
}

.card-support .card-item-showtext .card-item .card-i-text {
  margin-top: 15px;
  font-size: 16px;
  color: #dfdfdf;
  max-width: 300px;
  width: 100%;
}

@media screen and (max-width:991px) {

  .card-best-trading .card-item-showtext .card-item .card-i-heading,
  .card-best-trading .card-item-showtext .card-item .card-i-heading-sub,
  .card-best-trading .card-item-showtext .card-item .card-i-sub-text {
    position: relative;
  }

  .card-best-trading .card-item-showtext .card-item {
    display: contents;
  }

  .card-best-trading .card-item-showtext .card-item img {
    display: none;
  }

  .card-best-trading {
    margin-top: 0px;
  }



  .card-fundedfirm {
    margin-top: 0px;
  }

  .card-support {
    margin-top: 0px;
  }

  .card-besttrading .card-item-showtext .card-item .card-i-heading {
    margin: 20px 0px;
  }

  .card-besttrading .card-item-showtext .card-item img {
    position: absolute;
    right: 30px;
    top: 1px;
  }

}

@media screen and (max-width:576px) {
  .main-heading-choose {
    margin-left: 0px;
  }

  .m-empowering {
    margin: 25px 0px 0px 10px;
  }
}

@media screen and (max-width:511px) {
  .card-empowering .card-item-showtext .card-item video {
    width: 515px;
    /* height: 800px; */
  }

  .card-besttrading .card-item-showtext .card-item .card-i-heading {
    max-width: 300px;
  }

  .card-besttrading .card-item-showtext .card-item img {
    position: absolute;
    right: 30px;
    top: 18px;
  }

}

/*  -- section Why Funded Firm end --  */
/*  -- section Trader Feedback & Analysis start --  */

.main-heading-feedback {
  color: var(--white);
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 20px;
  text-transform: capitalize;
  /* padding: 4px 18px; */
  margin-left: 12px;
  position: relative;
}

.main-heading-feedback .sub-heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 50px;
  color: var(--main-florescent-green);
  text-align: center;
  max-width: 570px;
  width: 100%;
  margin: auto;
}

.main-heading-feedback .main-title {
  position: relative;
  width: 289px;
  padding: 3px;
  text-align: center;
  background-color: #0d1315;
  border: 1px solid transparent;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.main-heading-feedback .main-title:before {
  content: "";
  position: absolute;
  display: block;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  border-radius: inherit;
  -webkit-transition: opacity .15s ease-in-out 0s;
  -o-transition: opacity .15s ease-in-out 0s;
  -moz-transition: opacity .15s ease-in-out 0s;
  transition: opacity .15s ease-in-out 0s;
  /* opacity: 0; */
  background-image: -webkit-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -moz-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: -o-linear-gradient(178deg, #00998e, #ffffff, #ffbf58);
  background-image: linear-gradient(272deg, #00998e, #ffffff, #ffbf58);
  z-index: 0;
}

.main-heading-feedback .main-title .main-hea-bor {
  position: relative;
  width: 280px;
  padding: 7px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-shadow: 0 0 10px #00000070;
  -moz-box-shadow: 0 0 10px #00000070;
  box-shadow: 0 0 10px #00000070;
  border: 1px solid rgb(63, 59, 69);
  -webkit-transition: border .15s ease-in-out 0s;
  -o-transition: border .15s ease-in-out 0s;
  -moz-transition: border .15s ease-in-out 0s;
  transition: border .15s ease-in-out 0s;
  background-color: #0d1315;
  z-index: 1;
}

.main-heading-feedback .sub-heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 50px;
  color: var(--main-florescent-green);
  text-align: center;
  max-width: 570px;
  padding-bottom: 20px;
  width: 100%;
  margin: auto;
}

.main-card-white {
  background-color: white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: .75rem;
}

.main-card-black {
  background-color: #a7a7a726;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: .75rem;
  color: #FFFFFFB2;
}

.main-card-greensmoke {
  background-color: #4CEAFF;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: .75rem;
  color: #000000BF;
}

.main-card-greyblue {
  background-color: #d6d9f2;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: .75rem;
  color: #000000BF;
}

.main-card-greyblue .main-card-heading .card-heading {
  font-size: 42px;
  line-height: 1.5rem;
}

.main-card-analys .card-text img {
  width: 182px;
}

.main-card-analys .card-text {
  font-size: 14px;
  line-height: 1.5rem;
}



/*  */
.card-tradin-over {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 10px;
}

.card-tradin-over .col-auto {
  width: 280px;
  padding: 0 8px;
}

@media screen and (max-width: 575px) and (min-width: 0px) {
  .card-highli-over {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 10px;
  }

  .card-highli-over .col-auto {
    width: 280px;
    padding: 0 8px;
  }

  .card-highli-over .col-auto .card-item-flip {
    padding-bottom: 0;
  }

  .card-highli-over .col-auto .card-item-flip .ctf-inner>div {
    height: 100%;
  }

}

@media screen and (max-width:700px) {
  .main-heading-feedback .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 42px;
  }
}

@media screen and (max-width:576px) {
  .main-heading-feedback .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 35px;
  }
}

@media screen and (max-width:450px) {
  .main-heading-feedback .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 27px;
  }
}

@media screen and (max-width:420px) {
  .main-heading-feedback .sub-heading {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
  }
}

/*  */



/* --- Common highlight btns start --- */
.common-highlight-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.common-highlight-btns .chb-item {
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  max-width: 320px;
  width: 100%;
  background-size: 150%;
  transition: all 0.3s ease-in-out;
}

.common-highlight-btns .chb-item img {
  width: 36px;
  height: 36px;
  object-position: center;
  object-fit: contain;
}

.common-highlight-btns .chb-item.chb-color1 {
  background-image: linear-gradient(90deg, #256030, #75ea8b);
}

.common-highlight-btns .chb-item.chb-color2 {
  background-image: linear-gradient(90deg, #035f7b, #12bacc);
}

.common-highlight-btns .chb-item:hover {
  background-position: 100%;
}

@media screen and (max-width:768px) {
  .common-highlight-btns {
    flex-direction: column;
    gap: 15px;
  }

  .common-highlight-btns .chb-item img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width:576px) {
  .common-highlight-btns .chb-item {
    font-size: 14px;
  }
}

/* --- Common highlight btns end --- */


/* --- Step section css start --- */
.mine-section-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
}

.step-main-box {
  position: relative;
  display: block;
  margin: auto;
}

.step-main-box::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: calc(100% - 50px);
  border-radius: 50px;
  background-color: #a7a7a738;
}

.step-main-box .step-item-main-box .simb-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;
  width: calc(50% - 25px);
  margin-bottom: 10px;
}

.step-main-box .step-item-main-box:last-child .simb-inner {
  margin-bottom: 0px;
}

.step-main-box .step-item-main-box .simb-inner .simb-i-point {
  position: absolute;
  left: -31.5px;
  top: 19px;
  width: 13px;
  height: 13px;
  background-color: #75ea8b;
  background-image: linear-gradient(90deg, #22652f, #4ea55e);
  transform: rotate(45deg);
  z-index: 1;
  border-radius: 2px;
}

.step-main-box .step-item-main-box .simb-inner .simb-i-step {
  font-weight: 500;
  font-size: 16px;
  color: var(--main-florescent-green);
}

.step-main-box .step-item-main-box .simb-inner .simb-i-img {
  width: 50px;
  height: 50px;
}

.step-main-box .step-item-main-box .simb-inner .simb-i-title {
  font-size: 20px;
  color: var(--white);
  font-weight: 600;
}

.step-main-box .step-item-main-box .simb-inner .simb-i-text {
  font-size: 14px;
  color: #979797;
  max-width: 320px;
}

@media screen and (max-width:992px) {
  .mar-auto-btn {
    margin: auto;
  }
}


@media screen and (min-width:769px) {

  /*  */
  .step-main-box .step-item-main-box:nth-child(odd) .simb-inner {
    text-align: right;
  }

  .step-main-box .step-item-main-box:nth-child(odd) .simb-inner .simb-i-img,
  .step-main-box .step-item-main-box:nth-child(odd) .simb-inner .simb-i-text {
    margin-left: auto
  }

  .step-main-box .step-item-main-box:nth-child(odd) .simb-inner .simb-i-point {
    left: inherit;
    right: -31.5px;
  }


  /*  */
  .step-main-box .step-item-main-box:nth-child(even) .simb-inner {
    margin-left: auto;
  }

}

@media screen and (max-width:768px) {
  .step-main-box::after {
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .step-main-box .step-item-main-box {
    padding-left: 20px;
  }

  .step-main-box .step-item-main-box .simb-inner {
    width: 100%;
  }

  .step-main-box .step-item-main-box .simb-inner .simb-i-point {
    left: -16px;
  }

  .step-main-box .step-item-main-box .simb-inner .simb-i-text {
    max-width: 420px;
  }
}

@media screen and (max-width:576px) {
  .card-item-flip .card-item .card-i-img.img-w-h-60px {
    width: 45px;
    height: 45px;
  }
}

/* --- Step section css end --- */


/* --- Plans new design start --- */
.plans-step-btns-bx {
  border-radius: 50px;
  padding: 8px;
  max-width: fit-content;
  gap: 10px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a7a7a726;
  border: 1px solid #a7a7a738;
}

.plans-step-btns-bx .psb-item {
  cursor: pointer;
  padding: 8px;
  color: #ffffffbd;
  border-radius: 50px;
  width: 90px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.plans-step-btns-bx .psb-item.active,
.plans-step-btns-bx .psb-item:hover {
  color: white;
  background-color: #a7a7a726;
}


.plans-account-size-btns {
  display: none;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 20px 20px 0px 0px;
  background-color: #a7a7a726;
  border-bottom: 1px solid #a7a7a738;
}

.plans-account-size-btns .pasb-item {
  cursor: pointer;
  padding: 4px;
  color: #ffffffbd;
  border-radius: 50px;
  width: 70px;
  flex-basis: 70px;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.plans-account-size-btns .pasb-item.active {
  color: white;
  background-color: #a7a7a726;
}


.plans-table-responsive {
  width: 100%;
  border-radius: 20px;
  background-color: #a7a7a726;
  box-shadow: 3px 10px 15px -12px #333333, -3px 0px 15px -12px #333333;
  overflow: hidden;
  overflow-x: auto;
}

.plans-table {
  width: 100%;
}

.plans-table tbody td,
.plans-table thead th {
  padding: 12px 15px;
  text-align: center;
  border-width: 0 1px 0 1px;
  border-color: #8d8c8c6e;
  border-style: dotted;
  white-space: nowrap;
}

.plans-table thead th {
  padding-top: 24px;
}

.plans-table tbody td:first-child,
.plans-table thead th {
  border-style: solid;
}

.plans-table tbody td:first-child,
.plans-table thead th:first-child {
  border-width: 0 1px 0 0;
  width: 220px;
}

.plans-table tbody td:last-child,
.plans-table thead th:last-child {
  border-width: 0 0 0 1px;
}

.plans-table tbody td .plan-table-label,
.plans-table thead th .plan-table-label {
  color: white;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}

.plans-table thead th .plan-table-label {
  opacity: 0.5;
}

.plans-table thead th .plan-table-account {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  margin-bottom: 5px;
}

.plans-table-upper {
  padding: 15px;
  display: none;
}

.plans-table-upper .plan-get-btn,
.plans-table thead th .plan-get-btn {
  color: white;
  display: block;
  width: fit-content;
  padding: 5px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  color: white;
  background-image: linear-gradient(90deg, #035f7b, #12bacc);
}

.plans-table-upper .plan-get-btn .pgb-text,
.plans-table thead th .plan-get-btn .pgb-text {
  white-space: nowrap;
}

.plans-table-upper .plan-get-btn .pgb-fee,
.plans-table thead th .plan-get-btn .pgb-fee {
  opacity: 0.9;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.plans-table-upper .plan-get-btn {
  width: 100% !important;
  text-align: center;
  display: none;
}

.plans-table tbody td {
  color: #979797;
}

.plans-table tbody td .pt-right-icon {
  color: #3aa373
}

.plans-table tbody tr:hover {
  background-color: #ffffff05;
}

.plans-table tbody tr:hover td {
  border-style: solid;
}

.data-step3,
.data-step2,
.data-step1 {
  display: none;
  animation: fadeEffect 0.5s linear;
}

.show-step3 .data-step3,
.show-step2 .data-step2,
.show-step1 .data-step1 {
  display: inline;
}

@keyframes fadeEffect {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width:768px) {
  .plans-account-size-btns {
    display: flex;
  }

  .plans-table-responsive {
    border-radius: 0 0 20px 20px;
  }

  .plans-table thead {
    display: none;
  }

  .plans-table-upper {
    display: block;
  }

  .plans-table tbody tr td:not(:first-child) {
    display: none;
    border-width: 0px;
    font-weight: 600;
  }

  .plans-table tbody td:first-child,
  .plans-table thead th:first-child {
    width: 100%;
  }

  .plans-table thead th {
    padding-top: 12px;
  }

  .plans-table tbody td .plan-table-label,
  .plans-table thead th .plan-table-label {
    white-space: wrap;
  }

  .td-200k .plans-table-upper .plan-get-btn:nth-child(6),
  .td-100k .plans-table-upper .plan-get-btn:nth-child(5),
  .td-50k .plans-table-upper .plan-get-btn:nth-child(4),
  .td-25k .plans-table-upper .plan-get-btn:nth-child(3),
  .td-15k .plans-table-upper .plan-get-btn:nth-child(2),
  .td-5k .plans-table-upper .plan-get-btn:nth-child(1),

  .td-200k tbody tr td:nth-child(7),
  .td-100k tbody tr td:nth-child(6),
  .td-50k tbody tr td:nth-child(5),
  .td-25k tbody tr td:nth-child(4),
  .td-15k tbody tr td:nth-child(3),
  .td-5k tbody tr td:nth-child(2) {
    display: block;
    width: 100px;
  }

}

/* --- Plans new design end --- */




/* --- Traders reviews css start --- */
.traders-reviews-bx {
  width: 100%;
  position: relative;
}

.traders-review-item {
  width: 16.66%;
  position: relative;
  float: left;
  padding: 5px 5px 5px 5px;
}

.traders-review-item:nth-child(2) {
  margin-top: 60px;
}

.traders-review-item:nth-child(3) {
  margin-top: 25px;
}

.traders-review-item:nth-child(4) {
  margin-top: 50px;
}

.traders-review-item:nth-child(5) {
  margin-top: 20px;
}

.traders-review-item .tri-upper {
  background-color: white;
  border-radius: 12px;
  padding: 12px;
}

.traders-review-item .tri-upper .tri-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.traders-review-item .tri-upper .tri-inner .tri-img {
  width: 36px;
  height: 36px;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
}

.traders-review-item .tri-upper .tri-inner .tri-name {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.traders-review-item .tri-upper .tri-text {
  font-size: 14px;
  line-height: 1.5rem;
}

.traders-review-item.tri-black .tri-upper {
  background-color: #a7a7a726;
}

.traders-review-item.tri-black .tri-upper .tri-inner .tri-name,
.traders-review-item.tri-black .tri-upper .tri-text {
  color: white;
}

.traders-review-item.tri-green .tri-upper {
  background-color: #4CEAFF;
}

.traders-review-overlayer {
  background: -webkit-gradient(linear, left bottom, left top, from(#000000), to(transparent));
  background: -webkit-linear-gradient(bottom, #000000, transparent);
  background: -moz-linear-gradient(bottom, #000000, transparent);
  background: -o-linear-gradient(bottom, #000000, transparent);
  background: linear-gradient(0deg, #000000, transparent);
  height: 70%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.traders-review-overlayer.tro-top {
  background: linear-gradient(180deg, #000000, transparent);
  bottom: inherit;
  top: 0;
  z-index: -1;
}

@media screen and (max-width:1200px) {
  .traders-review-item {
    width: 20%;
  }

  .traders-review-item:nth-child(5) {
    margin-top: 0px;
  }

}

@media screen and (max-width:992px) {
  .traders-review-item {
    width: 25%;
  }

  .traders-review-item:nth-child(4) {
    margin-top: 0px;
  }
}

@media screen and (max-width:768px) {
  .traders-review-item {
    width: 33.3%;
  }

  .traders-review-item:nth-child(3) {
    margin-top: 0px;
  }
}

@media screen and (max-width:576px) {
  .traders-review-item {
    width: 50%;
  }

  .traders-review-item:nth-child(2) {
    margin-top: 0px;
  }

  .traders-review-item:nth-child(18),
  .traders-review-item:nth-child(17),
  .traders-review-item:nth-child(16),
  .traders-review-item:nth-child(15),
  .traders-review-item:nth-child(14),
  .traders-review-item:nth-child(13),
  .traders-review-item:nth-child(12),
  .traders-review-item:nth-child(11),
  .traders-review-item:nth-child(10),
  .traders-review-item:nth-child(9) {
    display: none;
  }
}

@media screen and (max-width:460px) {
  .traders-review-item {
    width: 100%;
  }
}

/* --- Traders reviews css end --- */


/*-------------------------
  tab section
  ---------------------------*/

.main-tab {
  position: relative;
  z-index: 1;
}

.main-tab ul li {
  line-height: 25px;
}

.plans-step-btns-bx .psb-item.cdv {
  white-space: nowrap;
  width: auto;
  padding: 8px 15px;
}

.psb-content {
  /* display: none; */
  padding: 0;
}

.psb-content.active {
  display: block;
}

@media screen and (max-width:767px) {
  .main-tab ul {
    padding-left: 15px;
  }
}