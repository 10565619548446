.footer {
    color: #fff;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    border-width: 2.5px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, rgba(80, 106, 131, 0) 3.5%, rgba(117, 234, 139, 0.5) 28%, rgba(117, 234, 139, 0.5) 68.5%, rgba(80, 106, 131, 0.13) 96.5%);
    border-image-slice: 1;
    border-top: none;
    padding-bottom: 70px;
    border-right: none;
}

.footer-logo {
    flex: 1;
    max-width: 350px;
}

.footer-logo img {
    width: 221px;
}

.footer-logo h2 {
    font-size: 24px;
    color: #00ff85;
    margin-bottom: 10px;
}

.footer-logo p {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    margin-bottom: 0px;
}

.footer-logo p.small {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #A4D5E2;
}

.footer-links {
    display: flex;
    flex: 2;
    justify-content: space-around;
    gap: 10px;
}

.footer-column h4 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #75EA8B;
    letter-spacing: 0.8px;

}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column li {
    margin-bottom: 5px;
    cursor: pointer;
}

.footer-column li a {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: #fff;
    letter-spacing: 0.8px;
    transition: all .3s ease-in-out;
}

.footer-column li a:hover {
    color: #75EA8B;
}

.footer-column p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #fff;
    letter-spacing: 0.8px;
    transition: all .3s ease-in-out;
}

.footer-column p span {
    display: block;
}

/* .footer-column li:hover {
    color: #00ff85;
} */

.newsletter-input {
    display: flex;
    margin-top: 12px;
    margin-bottom: 46px;
    position: relative;
}

.newsletter-input input {
    flex: 1 1;
    padding: 13px 55px 13px 13px;
    border: none;
    outline: none;
    border-radius: 10px;
    background: #00000036;
    border: 1px solid #FFFFFF3D;
    color: #ffffff;
    width: 100%;
}

.newsletter-input input::placeholder {
    color: #A4D5E2;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    letter-spacing: 0.7px;
}

.newsletter-input button {
    padding: 10px 20px;
    background: #75EA8B;
    color: #121212;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 6px;
    top: 6px;
}

.newsletter-input button svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.social-icons {
    display: flex;
    gap: 15.53px;
}

.social-icons i {
    font-size: 18px;
    cursor: pointer;
    color: #A4D5E2;
    transition: all .3s ease-in-out;
}

.social-icons i:hover {
    color: #75EA8B;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 65px;
}

.footer-bottom .footer-column {
    flex: 1;
    max-width: 290px;
}

.footer-bottom h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    padding-bottom: 20px;
    margin-bottom: 0;
    color: #ffffff;
}

.footer-bottom p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 0;
}